
<template>
  <div class="report" id="report" :class="theme">
    <div class="top">
      <header>
        <div class="label">
          <b>{{org_name}}</b> 报告分析<br>
          {{$formatTime(endTime,'y-m-d h:m')}}<span v-if="version">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v{{version}}</span>
        </div>
        <div v-if="!long && !hide" class="btn" @click="save">保存报告</div> 
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==0}" @click="tab(0)">报告说明</span>
        <span :class="{current:tabId==1}" @click="tab(1)">团队概况</span>
        <span :class="{current:tabId==2}" @click="tab(2)" v-if="team_risk.length">团队风险预警</span>
        <span :class="{current:tabId==3}" @click="tab(3)">团队活力</span>
        <span :class="{current:tabId==4}" @click="tab(4)">工作价<br>值倾向</span>
        <span v-if="team_leadership.show" :class="{current:tabId==5}" @click="tab(5)">领导能量</span>
        <span :class="{current:tabId==6}" @click="tab(6)">组织活力</span>
        <span :class="{current:tabId==7}" @click="tab(7)">职业幸<br>福指数</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 报告说明 -->
      <li class="explain" v-show="tabId==0 || long">
        <div class="view">
          <h3>报告说明</h3>
          <div><b>感谢您</b>的团队完成了组织活力扫描的评估，这是您的团队通过<b>数据赋能，迈向高绩效管理</b>的重要一步。</div>
          <br>
          <div>组织活力扫描的<b>作用</b>在于快速识别团队的<b>工作价值倾向、活力、领导能量、预警</b>等信息，并基于真实客观的数据，结合日常观察和分析，为团队管理改善提供科学、高效的依据。</div>
          <br>
          <div>当您<b>阅读</b>报告时，分数高或低并不代表好、坏、对、错，它只是团队当下真实的面貌，是员工体验层面的群体化学反应，这可能是您平时无法观察到或忽略的团队现状。</div>
          <br>
          <div>当您<b>应用</b>报告时，请结合团队文化、战略、机制、人才现状进行分析，并建议与核心管理层共同探讨和分析数据背后的原因，制定改善目标、行动方案，达成共识，采取一致行动，提高报告的应用价值。</div>
          <br>
          <div>组织活力扫描<b>报告</b>由{{part.length||'多'}}部分组成：<span v-for="(it,i) in part" :key="i">{{i+1}}.{{it}}</span>帮助您带领团队更好的发展。</div>
        </div>
      </li>
      <!-- 团队概况 -->
      <li v-show="tabId==1 || long">
        <div class="view">
          <h3>团队基本信息</h3>
          <div class="text">
            <b>团队基本信息：</b>包含参评人员的性别比例、层级比例、学历分布、年龄分布的信息。
          </div>
          <div class="text">
            <b>应用建议：</b>对照团队人才结构与标准，可根据公司人才标准进行动态管理。
          </div>
          <div class="message" :class="{messageMini:width<330}">
            <div class="above">
              <div class="box">
                <h4>性别</h4>
                <div class="line" v-for="(item,index) in basic_info.sex_info" :key="index">
                  <h5>{{item.name}}</h5>
                  <fan :data="item.rate" :index="index" :animate="!long"/>
                  <h6>{{item.number}}人,{{item.rate}}%</h6>
                </div>
                <div class="number">总人数：{{totalNum}}人</div>
              </div>
              <div class="box">
                <h4>层级</h4>
                <div class="line" v-for="(item,index) in basic_info.tier_info" :key="index">
                  <h5>{{item.name}}</h5>
                  <fan :data="item.rate" :index="index" :animate="!long"/>
                  <h6>{{item.number}}人,{{item.rate}}%</h6>
                </div>
              </div>
            </div>
            <div class="under">
              <div class="box">
                <h4>学历</h4>
                <div class="line" v-for="(item,index) in basic_info.educational_info" :key="index">
                  <h5>{{item.name}}</h5>
                  <fan :data="item.rate" :index="index" :animate="!long"></fan>
                  <h6>{{item.number}}人,{{item.rate}}%</h6>
                </div>
              </div>
              <div class="box">
                <h4>年龄</h4>
                <div class="line" v-for="(item,index) in basic_info.era_info" :key="index">
                  <h5>{{item.name}}</h5>
                  <fan :data="item.rate" :index="index" :animate="!long"></fan>
                  <h6>{{item.number}}人,{{item.rate}}%</h6>
                </div>
                <div class="number">平均年龄：{{basic_info.avg_age}}岁</div>
              </div>
            </div>
          </div>
        </div>

        <div class="view">
          <h3>团队画像</h3>
          <div class="text">
            <b>团队画像：</b>让您的团队情况一目了然。画像中心是团队成员对工作的需求倾向和行为偏好；【原动力】是驱使团队采取行动的内在力量；【职业素质】是团队成员支撑工作行为的能力基础；【领导能量】是管理层带领团队实现目标的能量；【团队活力】是团队发展和创新的原动力来源。
          </div>
          <div class="text">
            <b>应用建议：</b>从全局视角，快速识别团队成员的需求、动力、能力，从组织思维进行系统改善。
          </div>
          <target 
            :activity="team_activity.TVI.score" 
            :leadership="team_leadership.leadership.score" 
            :motivity="team_motivity.team_motivity.score" 
            :quality="team_quality.team_quality.score" 
            :dimension="team_workvalue.top_dimension"
            :theme="theme"
          ></target>
        </div>
      </li>
      <!-- 团队风险预警 -->
      <li v-show="tabId==2 || long" v-if="team_risk.length">
        <div class="view teamRisk">
          <h3>团队风险预警</h3>
          <div v-for="(item,i) in team_risk" :key="i">
            <img :src="require(`../../../assets/images/report/risk_${item.risk_level}.png`)">
            <div class="txt"><b>{{item.risk_name}}：</b>{{item.risk_level=='danger'?'已经形成':'正在形成'}}</div>
            <div class="text"><b>风险说明：</b>{{item.description}}</div>
            <div class="text"><b>比喻：</b>{{item.metaphor}}</div>
            <div class="text"><b>影响：</b>{{item.influence}}</div>
            
            <h3>管理建议</h3>
            <dl>
              <dt @click="expand(i,'A')" :class="{fold:riskOpen[i].A,'flex-b':!long}">1. 优先</dt>
              <dd :class="{open:riskOpen[i].A||long}">
                <div v-for="(it,j) in item.priority" :key="'sug'+j">
                  <h5>{{it.title}}</h5>
                  <p v-for="p in it.description" :key="p">{{p}}</p>
                </div>
              </dd>
              <dt @click="expand(i,'B')" :class="{fold:riskOpen[i].B,'flex-b':!long}" style="background:#9e80f1">2. 重要</dt>
              <dd :class="{open:riskOpen[i].B||long}">
                <div v-for="(it,j) in item.importance" :key="'sug'+j">
                  <h5>{{it.title}}</h5>
                  <p v-for="p in it.description" :key="p">{{p}}</p>
                </div>
              </dd>
              <dt @click="expand(i,'C')" :class="{fold:riskOpen[i].C,'flex-b':!long}" style="background:#7bc495">3. 长期行动</dt>
              <dd :class="{open:riskOpen[i].C||long}">
                <div v-for="(it,j) in item.action" :key="'sug'+j">
                  <h5>{{it.title}}</h5>
                  <p v-for="p in it.description" :key="p">{{p}}</p>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </li>
      <!-- 团队活力 -->
      <li v-show="tabId==3 || long">
        <div class="view">
          <h3>团队活力分析</h3>
          <div class="text">
            <b>团队活力分析：</b>从7个方面探索团队活力激发程度，综合反映了团队文化、管理机制、管理层的领导风格和能力、以及团队成员的心态和能力水平。团队活力因子数据分布在五个区间，不同区间反映不同的状态，“警示区”是您需要特别留意的；“中等”、“良好”、“优秀”是需要定期评估、跟进和提升的。
          </div>
          <div class="text">
            <b>应用建议：</b>关注7个活力因子的分布区间，探讨它们之间的相互关联关系，及早防范团队深层次的风险。
          </div>
          <bar ref="barRow1" :width="width*.85" :data="team_activity.dimension" :animate="!long" :onclick="true" :label="'中高层感知水平'"/>

          <h3>提示</h3>
          <div class="text">
            <b>提示：</b>以测评数据为基础，从单个活力因子和团队整体两个层面进行提示，避免团队发展可能面临的阻碍。
          </div>
          <div class="word" v-if="team_activity.low_dimension.length || team_activity.top_dimension.length">
            <div>活力因子</div>
            <div class="risk risk1" v-for="(item,i) in team_activity.low_dimension" :key="i">
              <b>{{item.name}}</b>：{{item.content}}
            </div>
            <div class="risk risk1" v-for="(item,i) in team_activity.top_dimension" :key="i">
              <b>{{item.name}}</b>：{{item.content}}
            </div>
          </div>
        </div>
      </li>
      <!-- 工作价值倾向 -->
      <li v-show="tabId==4 || long">
        <div class="view">
          <h3>工作价值倾向分析</h3>
          <div class="text">
            <b>工作价值倾向分析：</b>深入了解团队成员对工作的内在需求和期待，通过团队文化、激励机制、优质培训、发展平台等管理方式有效激励团队成员，也可以针对个体成员进行个性化的激励和辅导，持续赋能人才和组织发展。
          </div>
          <div class="text">
            <b>应用建议：</b>根据报告中的激励建议，对照团队激励机制和方法是否能够满足员工的需求，尽快调整团队文化和激励机制，并对团队管理者进行关于沟通和激励的专业训练。
          </div>
          <div class="rank">
            <div class="box" :class="'box'+(i+1)" v-for="(item,i) in team_workvalue.top_dimension" :key="i">{{item.name}}<span>NO.{{i+1}}</span></div>
          </div>

          <h1>激励方向：</h1>
          <p v-for="(e,j) in team_workvalue.top_dimension" :key="j">{{e.content}}</p>
        </div>
      </li>
      <!-- 领导能量 -->
      <li v-show="tabId==5 || long" v-if="team_leadership.show">
        <div class="view">
          <h3>领导能量分析</h3>
          <div class="text">
            <b>领导能量分析：</b>从6个领导行为分析管理者的角色认知、领导力认知水平，了解管理者在当下的领导行为是否匹配组织的需要，领导行为数据将分布在五个区间，不同区间反映不同的状态，“警示区”是您需要特别留意的；“中等”、“良好”、“优秀”是需要定期评估、跟进和提升的。
          </div>
          <div class="text">
            <b>应用建议：</b>结合团队管理者的现状和能力水平，按6个领导行为的数据，制定合宜、针对性的领导力发展计划。
          </div>
          <bar :width="width*.85" :data="team_leadership.dimension" :color="['#51b8fb','#28c8b7','#4c88f3','#ed7d31','#6c7bf1','#e96842']" :animate="!long" :label="'中高层感知水平'"/>
          <h3>提示</h3>
          <div class="text">
            <b>提示：</b>以测评数据为基础，从关键领导行为进行提示，及时改善，避免可能阻碍领导能量发挥的行为。
          </div>
          <div class="word" v-if="team_leadership.low_dimension.length || team_leadership.top_dimension.length">
            <div></div>
            <div class="risk risk1" v-for="(item,i) in team_leadership.low_dimension" :key="i">
              <b>{{item.name}}</b>：{{item.content}}
            </div>
            <div class="risk risk1" v-for="(item,i) in team_leadership.top_dimension" :key="i">
              <b>{{item.name}}</b>：{{item.content}}
            </div>
          </div>
        </div>
      </li>
      <!-- 组织活力 -->
      <li v-if="tabId==6 || long">
        <div class="view org">
          <h3>组织活力分析</h3>
          <organize :click="!long" :dimension="organize.dimension" :data="organize.dimension_region"/>
          <p>点在圈内，表示该区域需要改善提升的空间较大，请参照该区域相关要素提升完善。</p>
          <p>点在圈外，表示该区域水平较好，但依然可以参照相关要素进一步提升。</p>
          <h3>改善建议</h3>
          <dl>
            <dt>A区：组织动能</dt>
            <div class="sub" @click="open1=!open1" :class="{fold:open1,'flex-b':!long}">优先主动</div>
            <dd :class="{open:open1||long}">
              <h3>基本需求（薪酬福利）</h3>
              <h6>解决燃眉之急：<br>薪酬福利是员工最基本的需求，直接影响生存和安全感。优先保障公平且有竞争力的薪酬福利是稳定员工队伍、减少人员流失的第一步，也是其他激励措施能够生效的基础。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1-2周): 市场调研与内部评估</h6>
              <p>进行薪酬福利市场调研，了解行业平均水平及竞争对手情况。</p>
              <p>对内部现有薪酬福利体系进行评估，找出差距和问题。</p>
              <p>与财务部门沟通，了解薪酬调整的预算空间。</p>
              <h6>步骤 2 (1周): 方案设计与沟通</h6>
              <p>根据调研结果和预算，设计具有竞争力的薪酬福利方案，重点关注关键岗位和绩优员工。</p>
              <p>与员工代表进行沟通，收集反馈意见并进行方案微调。</p>
              <h6>步骤 3 (1周): 方案实施与宣贯</h6>
              <p>发布新的薪酬福利方案，并进行详细解读。</p>
              <p>提供咨询渠道，解答员工疑问。</p>
              <h6>步骤 4 (持续): 跟踪与反馈</h6>
              <p>定期收集员工反馈，评估方案的实施效果。</p>
              <p>根据反馈意见和市场变化，适时调整方案。</p>
              <h6>衡量指标: 员工满意度调查、离职率、招聘成功率。</h6>

              <h3>绩效管理</h3>
              <h6>快速提升效率：<br>清晰的绩效目标、及时的反馈和辅导，能够迅速明确工作方向，提升个人和团队的执行力，对短期内改善业绩至关重要。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 建立或优化绩效管理体系</h6>
              <p>明确绩效管理的目标和原则。</p>
              <p>设定清晰、可衡量的绩效目标（SMART原则）。</p>
              <p>选择合适的绩效考核方法（如KPI、OKR等）。</p>
              <p>制定绩效考核周期和流程。</p>
              <h6>步骤 2 (1周): 培训与宣贯</h6>
              <p>对管理者进行绩效管理培训，使其掌握绩效辅导、反馈的技巧。</p>
              <p>对员工进行绩效管理体系的宣贯，使其了解考核标准和流程。</p>
              <h6>步骤 3 (持续): 执行与反馈</h6>
              <p>管理者定期与员工进行绩效沟通，提供反馈和指导。</p>
              <p>按周期进行绩效考核，并根据考核结果进行奖惩和改进。</p>
              <p>定期收集员工对绩效管理体系的反馈，并进行优化。</p>
              <h6>步骤 4 (每季度): 复盘与优化</h6>
              <p>季度进行绩效管理执行情况的总结与分析。</p>
              <p>收集各方面意见，优化绩效管理体系，例如指标设定、沟通频率。</p>
              <h6>衡量指标: 目标达成率、工作效率提升、员工满意度。</h6>

              <h3>敬业度</h3>
              <h6>及时止损挽救：<br>低敬业度意味着低效率、高风险和潜在的人员流失。快速识别并解决影响敬业度的突出问题（如沟通不畅、工作负担过重等），可以有效止损，避免情况恶化。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周): 敬业度调查与分析</h6>
              <p>通过问卷调查、访谈等方式，了解员工的敬业度水平和影响因素。</p>
              <p>对调查结果进行分析，找出影响敬业度的关键问题。</p>
              <h6>步骤 2 (2周): 制定行动计划</h6>
              <p>针对关键问题，制定具体的行动计划，明确责任人和时间节点。例如：沟通不畅，可以每周一次部门会议；工作负担过重，重新梳理工作流程，合理分配任务。</p>
              <h6>步骤 3 (持续): 执行与跟进</h6>
              <p>执行行动计划，并定期跟进实施情况。</p>
              <p>与员工保持沟通，及时了解他们的反馈和需求。</p>
              <h6>步骤 4 (每月): 效果评估与调整</h6>
              <p>每月进行敬业度调查或进行小型座谈，评估行动计划的效果。</p>
              <p>根据评估结果，调整行动计划，确保问题得到有效解决。</p>
              <h6>衡量指标: 敬业度调查得分、员工流失率、生产力指标。</h6>
            </dd>
            <div class="sub" @click="open2=!open2" :class="{fold:open2,'flex-b':!long}">重要工作</div>
            <dd :class="{open:open2||long}">
              <h3>领导力</h3>
              <h6>引领方向，激发团队：<br>优秀的领导者能够明确愿景、有效沟通、激励团队，并营造积极的团队氛围，是实现组织目标的关键驱动力。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1-2周): 评估与需求分析</h6>
              <p>通过360度评估、领导力测评等方式，评估现有管理者的领导力水平。</p>
              <p>通过访谈、问卷调查等方式，了解员工对领导力的期望和需求。</p>
              <h6>步骤 2 (1个月): 领导力发展项目设计</h6>
              <p>根据评估结果和需求分析，设计针对性的领导力发展项目，可以包括培训、教练、行动学习等方式。</p>
              <p>制定清晰的项目目标、时间表和评估方法。</p>
              <p>培训内容可包括：愿景设定、沟通技巧、团队建设、激励方法、变革管理等。</p>
              <h6>步骤 3 (持续): 项目实施与跟进</h6>
              <p>按照计划实施领导力发展项目。</p>
              <p>定期跟进项目进展，并提供必要的支持和资源。</p>
              <h6>步骤 4 (每季度/半年): 效果评估与反馈</h6>
              <p>通过行为观察、绩效考核、员工反馈等方式，评估领导力发展项目的效果。</p>
              <p>根据评估结果，调整项目内容和实施方式。</p>
              <h6>衡量指标: 员工满意度调查、团队绩效、360度评估结果、领导力测评提升。</h6>

              <h3>心理需求（成就感）</h3>
              <h6>内在驱动，持续激励：<br>认可和成就感能够激发员工的内在动机，促使他们主动承担责任、追求卓越。这是提高员工满意度、激发创造力的重要因素。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1-2周): 现状分析与方案设计</h6>
              <p>分析现有工作中能够提供成就感的因素和不足之处。</p>
              <p>设定清晰、可衡量的绩效目标（SMART原则）。</p>
              <p>设计具体的方案，例如：</p>
              <p>认可计划: 设立月度/季度优秀员工奖，公开表彰突出贡献。</p>
              <p>授权与责任: 给予员工更多的工作自主权和决策权。</p>
              <p>挑战性任务: 为员工提供具有挑战性的工作任务，并给予支持。</p>
              <p>反馈机制: 建立及时、具体的反馈机制，让员工了解自己的工作成果。</p>
              <h6>步骤 2 (持续): 方案实施与宣传</h6>
              <p>积极宣传认可计划，让员工了解评选标准和流程。</p>
              <p>管理者在日常工作中积极运用授权、提供挑战性任务、及时反馈等方式。</p>
              <h6>步骤 3 (每月): 效果评估与反馈</h6>
              <p>收集员工对认可计划的反馈意见。</p>
              <p>观察员工的工作积极性和主动性是否有所提升。</p>
              <h6>衡量指标: 员工满意度调查、员工参与度、创新提案数量。</h6>

              <h3>激励体系</h3>
              <h6>强化行为，促进目标达成：<br>合理的激励体系能够将员工的个人利益与组织目标紧密结合，强化与目标一致的行为，引导员工为实现组织目标而努力。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 现状分析与目标设定</h6>
              <p>分析现有激励体系的优缺点，明确需要改进的方面。</p>
              <p>结合组织目标，确定激励体系需要支持的关键行为和结果。</p>
              <h6>步骤 2 (2周): 设计激励方案</h6>
              <p>设计多元化的激励方案，包括物质激励（奖金、福利、股权等）和非物质激励（荣誉、晋升、培训机会等）。</p>
              <p>确保激励方案与绩效考核体系紧密挂钩。</p>
              <h6>步骤 3 (1周): 沟通与宣贯</h6>
              <p>与员工充分沟通激励方案，确保其理解方案内容和目标。</p>
              <p>对激励方案进行广泛宣传，营造积极的氛围。</p>
              <h6>步骤 4 (持续): 实施与评估</h6>
              <p>按照方案执行激励措施，并记录执行情况。</p>
              <p>定期评估激励方案的效果，根据实际情况进行调整。</p>
              <h6>衡量指标: 关键绩效指标达成率、员工满意度、员工行为改变。</h6>

              <h3>人才评价</h3>
              <h6>公平公正，激励发展：<br>公正透明的人才评价体系能够客观反映员工的贡献，为薪酬调整、晋升、培训等人力资源决策提供依据，激励员工不断提升自身能力。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 现状评估与体系设计</h6>
              <p>评估现有人才评价体系的公平性、公正性和有效性。</p>
              <p>设计或优化人才评价体系，包括评价标准、评价方法、评价流程、评价周期等。</p>
              <p>评价标准应与岗位职责和绩效目标相一致，评价方法应多样化（如：KPI、行为评价、360度评估等）。</p>
              <h6>步骤 2 (1周): 培训与宣贯</h6>
              <p>对评价者进行培训，使其掌握评价标准和方法。</p>
              <p>对员工进行宣贯，使其了解评价体系的流程和意义。</p>
              <h6>步骤 3 (持续): 实施与反馈</h6>
              <p>按照评价周期进行人才评价，并及时向员工反馈评价结果。</p>
              <p>建立申诉机制，确保评价过程的公平公正。</p>
              <h6>步骤 4 (每年): 体系回顾与优化</h6>
              <p>每年对人才评价体系进行回顾和优化，确保其持续有效。</p>
              <h6>衡量指标: 员工对评价体系的满意度、评价结果的信度和效度、评价结果的应用程度（如：晋升、调薪等）。</h6>
            </dd>
            <div class="sub" @click="open3=!open3" :class="{fold:open3,'flex-b':!long}">长期行动</div>
            <dd :class="{open:open3||long}">
              <h3>成长需求（目的意义）</h3>
              <h6>长远发展，深度绑定：<br>帮助员工实现个人价值和职业目标，让他们看到在组织中的长远发展前景，能够增强员工对组织的归属感和忠诚度，实现员工与组织的共同成长。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 需求调研与分析</h6>
              <p>通过职业规划访谈、问卷调查等方式，了解员工的职业发展需求和期望。</p>
              <p>分析员工的职业发展需求与组织发展需求的匹配程度。</p>
              <h6>步骤 2 (2周): 职业发展体系构建</h6>
              <p>建立或完善职业发展通道，为员工提供清晰的职业发展路径。</p>
              <p>设计个性化的职业发展计划，帮助员工实现职业目标。</p>
              <p>将员工的个人职业规划与公司的战略发展相绑定。</p>
              <h6>步骤 3 (持续): 辅导与支持</h6>
              <p>管理者定期与员工进行职业发展沟通，提供指导和支持。</p>
              <p>提供必要的资源和机会，帮助员工实现职业发展计划。</p>
              <h6>步骤 4 (每年): 评估与调整</h6>
              <p>评估员工职业发展计划的执行情况和效果。</p>
              <p>根据评估结果和员工反馈，调整职业发展计划和支持措施。</p>
              <h6>衡量指标: 员工满意度、员工留任率、内部晋升比例。</h6>

              <h3>人才规划</h3>
              <h6>战略储备，未雨绸缪：<br>根据组织战略目标，预测未来人才需求，并制定相应的人才招聘、培养和储备计划，确保组织在未来发展中拥有充足的人才支持，是企业基业长青的保障。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1个月): 战略分析与人才盘点</h6>
              <p>根据组织战略目标，分析未来所需的人才类型和数量。</p>
              <p>对现有人才队伍进行盘点，评估人才现状与未来需求的差距。</p>
              <h6>步骤 2 (1个月): 人才规划方案制定</h6>
              <p>制定人才招聘计划，明确招聘渠道、招聘标准和招聘流程。</p>
              <p>制定人才培养计划，包括培训内容、培训方式、培训周期等。</p>
              <p>制定人才储备计划，识别高潜人才，并制定针对性的培养和发展措施。</p>
              <h6>步骤 3 (持续): 执行与监控</h6>
              <p>按照人才规划方案执行各项计划，并定期监控执行情况。</p>
              <p>根据实际情况，及时调整人才规划方案。</p>
              <h6>步骤 4 (每年): 回顾与更新</h6>
              <p>每年对人才规划方案进行回顾和更新，确保其与组织战略目标保持一致。</p>
              <h6>衡量指标: 关键岗位人才储备率、人才招聘周期、人才培养计划完成率。</h6>

              <h3>人才发展</h3>
              <h6>持续赋能，保持竞争力：<br>通过系统的培训和发展计划，持续提升员工的知识、技能和综合素质，使组织能够适应不断变化的市场环境，保持长期的竞争优势。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 需求分析与体系设计</h6>
              <p>结合组织战略和员工需求，分析人才发展的重点领域和方向。</p>
              <p>设计或完善人才发展体系，包括培训体系、导师制、轮岗制等。</p>
              <p>建立多元化的学习资源库，提供线上线下学习资源。</p>
              <h6>步骤 2 (持续): 计划实施与资源支持</h6>
              <p>制定年度人才发展计划，并提供必要的资源和支持。</p>
              <p>鼓励员工积极参与学习和发展活动。</p>
              <p>对关键岗位和高潜人才提供定制化的培养方案。</p>
              <h6>步骤 3 (每季度/半年): 效果评估与反馈</h6>
              <p>通过考试、评估、项目实践等方式，评估人才发展项目的效果。</p>
              <p>收集员工反馈，优化人才发展体系和实施方式。</p>
              <h6>衡量指标: 培训覆盖率、培训满意度、员工能力提升程度、关键岗位继任者准备度。</h6>
            </dd>

            <dt style="background:#b488f1">B区：组织战略</dt>
            <div class="sub" @click="open4=!open4" :class="{fold:open4,'flex-b':!long}">优先主动</div>
            <dd :class="{open:open4||long}">
              <h3>战略澄清</h3>
              <h6>稳定军心，减少内耗：<br>在战略模糊或调整期，及时、透明地沟通至关重要。即使战略尚未完全清晰，也应主动向员工说明当前情况、已知信息和调整方向，解释战略为什么需要做调整，以减少猜测和焦虑，为后续工作争取时间和信任。建立双向沟通机制，收集员工的反馈和建议。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (立即): 领导层内部沟通与对齐</h6>
              <p>领导层进行会议，坦诚沟通当前面临的挑战和战略调整的必要性。</p>
              <p>对已知信息、调整方向和原因进行梳理，形成初步的沟通口径。</p>
              <p>明确沟通的目标、对象、方式和时间表。</p>
              <h6>步骤 2 (1周内): 分层级沟通</h6>
              <p>由 CEO 或高层领导向全体员工发布公开信或召开全体大会，说明战略调整的背景、原因和初步方向。</p>
              <p>各部门负责人向其团队成员进行更详细的沟通，解答疑问，收集反馈。</p>
              <p>设立专门的沟通渠道（如邮箱、内部论坛等），收集员工的意见和建议。</p>
              <h6>步骤 3 (持续): 定期更新与沟通</h6>
              <p>根据战略的细化和执行情况，定期向员工通报进展和调整。</p>
              <p>对员工关心的问题进行及时回应和解答。</p>
              <h6>步骤 4 (视情况而定): 组建跨部门沟通小组</h6>
              <p>挑选各部门的沟通大使，及时同步信息，避免信息在传递中变形。</p>
              <h6>衡量指标: 员工对战略调整的理解程度、焦虑情绪的缓解程度。</h6>
              
              <h3>内外部环境分析</h3>
              <h6>快速摸底，找准方向：<br>全面、客观地分析内外部环境，识别企业的优势、劣势、机遇和威胁（SWOT），以及政治、经济、社会、技术、法律、环境 (PESTLE) 等外部因素。进行风险评估，识别潜在风险，并制定初步的应对措施。为战略制定提供数据支持和决策依据。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周): 组建分析小组</h6>
              <p>成立跨部门的分析小组，成员应包括来自市场、销售、研发、运营、财务等部门的骨干人员。</p>
              <p>明确小组的职责、分工和时间表。</p>
              <h6>步骤 2 (2周): 数据收集与分析</h6>
              <p>利用 PESTLE 模型，系统地收集外部环境数据（政治、经济、社会、技术、法律、环境）。</p>
              <p>利用 SWOT 模型，分析企业的内部优势、劣势，以及外部的机会和威胁。</p>
              <p>进行竞争对手分析，了解其战略、优势和劣势。</p>
              <p>识别关键的风险因素，并进行初步的风险评估。</p>
              <h6>步骤 3 (1周): 报告撰写与汇报</h6>
              <p>将分析结果整理成报告，并向领导层进行汇报。</p>
              <p>报告应包括关键发现、结论和建议。</p>
              <h6>步骤 4 (持续): 动态监测</h6>
              <p>建立内外部环境的监测机制，定期更新数据和分析结果。</p>
              <h6>衡量指标: 分析报告的质量、对战略制定的支持程度。</h6>
              
              <h3>资源评估</h3>
              <h6>盘点资源，评估能力：<br>初步评估企业当前的人力、财力、物力、技术、品牌等资源状况，以及核心能力，确保后续制定的目标和战略规划具有可行性，避免不切实际或超出能力范围。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周): 确定评估框架</h6>
              <p>明确资源评估的范围，包括人力、财力、物力、技术、品牌、信息等。</p>
              <p>制定评估标准和方法。</p>
              <h6>步骤 2 (2周): 数据收集与评估</h6>
              <p>各部门根据评估框架，收集相关数据，并进行自我评估。</p>
              <p>人力资源：员工数量、技能、经验、绩效等。</p>
              <p>财务资源：资金状况、盈利能力、融资能力等。</p>
              <p>物质资源：设备、设施、库存等。</p>
              <p>技术资源：专利、核心技术、研发能力等。</p>
              <p>品牌资源：品牌知名度、美誉度、影响力等。</p>
              <p>信息资源：数据、信息系统等。</p>
              <p>对核心能力进行评估，识别企业的竞争优势。</p>
              <h6>步骤 3 (1周): 汇总与报告</h6>
              <p>将各部门的评估结果进行汇总，形成全面的资源评估报告。</p>
              <p>向领导层汇报评估结果，指出资源优势和短板。</p>
              <h6>衡量指标: 评估报告的完整性、准确性、对战略制定的参考价值。</h6>
              
              <h3>订立目标</h3>
              <h6>凝聚共识，聚焦行动：<br>基于现有信息和初步战略方向，设定短期（如季度性）、可实现、可衡量的目标。这有助于团队聚焦，将精力集中于可执行的任务上，并在执行过程中不断校准方向。目标需要具有一定的挑战性，以激发团队的斗志。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周): 目标制定工作坊</h6>
              <p>组织领导层和关键部门负责人参加目标制定工作坊。</p>
              <p>回顾内外部环境分析和资源评估的结果。</p>
              <p>基于初步的战略方向，讨论并设定短期（如季度性）目标。</p>
              <p>运用 SMART 原则（具体、可衡量、可实现、相关、有时限）制定目标。</p>
              <h6>步骤 2 (1周): 目标分解与沟通</h6>
              <p>将总体目标分解到各个部门和团队。</p>
              <p>与各部门负责人沟通，确保其对目标的理解和认同。</p>
              <h6>步骤 3 (持续): 目标跟踪与调整</h6>
              <p>建立目标跟踪机制，定期检查目标的执行情况。</p>
              <p>根据实际情况，对目标进行必要的调整。</p>
              <h6>衡量指标: 目标的清晰度、可衡量性、挑战性、团队对目标的认同度、目标达成率。</h6>
            </dd>
            <div class="sub" @click="open5=!open5" :class="{fold:open5,'flex-b':!long}">重要工作</div>
            <dd :class="{open:open5||long}">
              <h3>使命</h3>
              <h6>回归初心，明确目的：<br>重新审视并明确企业的根本目的和存在的意义（即：我们为什么存在？为谁创造价值？），确保所有战略活动都围绕这一核心价值展开。这是战略制定的原点和基石。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周): 回顾与反思</h6>
              <p>组织领导层和核心员工回顾企业创立的初衷和发展历程。</p>
              <p>反思企业存在的意义和价值。</p>
              <h6>步骤 2 (2周): 使命提炼工作坊</h6>
              <p>通过工作坊的形式，引导参与者讨论并提炼企业的使命。</p>
              <p>回答以下问题：我们为什么存在？我们为谁创造价值？我们的核心业务是什么？</p>
              <p>形成使命宣言的初稿。</p>
              <h6>步骤 3 (1周): 征求意见与修订</h6>
              <p>将使命宣言的初稿向员工、客户、合作伙伴等利益相关者征求意见。</p>
              <p>根据反馈意见，对使命宣言进行修订和完善。</p>
              <h6>步骤 4 (持续): 宣贯与践行</h6>
              <p>通过各种渠道宣贯企业的使命，确保所有员工都理解并认同。</p>
              <p>将使命融入到企业的各项工作中，确保所有决策和行动都与使命保持一致。</p>
              <h6>衡量指标: 使命宣言的清晰度、员工对使命的理解和认同度、企业行为与使命的一致性。</h6>

              <h3>愿景</h3>
              <h6>勾勒蓝图，指引方向：<br>基于使命，展望并描绘企业未来期望达到的状态（即：我们将来要成为什么？）。清晰的愿景能够激发员工的热情和创造力，为战略规划提供长期目标和方向。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周): 愿景展望工作坊</h6>
              <p>组织领导层和核心员工参加愿景展望工作坊。</p>
              <p>基于企业的使命，展望未来 5-10 年企业希望达到的状态。</p>
              <p>回答以下问题：我们将来要成为什么？我们在行业中的地位如何？我们的产品或服务将如何改变世界？</p>
              <h6>步骤 2 (2周): 愿景描述与提炼</h6>
              <p>将工作坊的讨论结果整理成愿景描述。</p>
              <p>提炼出简洁、有力、鼓舞人心的愿景宣言。</p>
              <h6>步骤 3 (1周): 征求意见与修订</h6>
              <p>将愿景宣言向员工、客户、合作伙伴等利益相关者征求意见。</p>
              <p>根据反馈意见，对愿景宣言进行修订和完善。</p>
              <h6>步骤 4 (持续): 宣贯与激励</h6>
              <p>通过各种渠道宣贯企业的愿景，激发员工的热情和创造力。</p>
              <p>将愿景融入到企业的各项工作中，为战略规划提供长期目标和方向。</p>
              <h6>衡量指标: 愿景宣言的清晰度、鼓舞人心程度、员工对愿景的认同度和向往程度。</h6>

              <h3>战略规划与执行</h3>
              <h6>制定路径，落实行动：<br>基于使命和愿景，结合内外部环境分析和资源评估的结果，制定实现目标的具体战略路径（即：我们如何实现目标？）。明确战略重点、关键举措、时间表和责任人。建立有效的执行、监督和反馈机制，确保战略规划得到贯彻落实，并根据实际情况不断调整优化。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 战略规划工作坊</h6>
              <p>组织领导层和关键部门负责人参加战略规划工作坊。</p>
              <p>回顾使命、愿景、内外部环境分析和资源评估的结果。</p>
              <p>确定战略重点和关键举措。</p>
              <p>制定行动计划，明确时间表和责任人。</p>
              <h6>步骤 2 (1周): 战略沟通与宣贯</h6>
              <p>向全体员工沟通战略规划，确保其理解战略目标、重点和关键举措。</p>
              <p>将战略目标分解到各个部门和团队，并制定相应的绩效考核指标。</p>
              <h6>步骤 3 (持续): 执行、监督与反馈</h6>
              <p>建立有效的执行机制，确保各项任务按计划推进。</p>
              <p>建立监督机制，定期检查战略执行情况，及时发现问题并进行纠正。</p>
              <p>建立反馈机制，收集团队和利益相关者的意见和建议，不断优化战略规划。</p>
              <h6>步骤 4 (每季度/半年): 战略回顾与调整</h6>
              <p>定期组织战略回顾会议，评估战略执行效果，并根据实际情况进行调整。</p>
              <h6>衡量指标: 战略规划的完整性、可执行性、战略目标的达成率、战略执行的效率。</h6>
            </dd>
            <div class="sub" @click="open6=!open6" :class="{fold:open6,'flex-b':!long}">长期行动</div>
            <dd :class="{open:open6||long}">
              <h3>文化梳理与建设</h3>
              <h6>塑造土壤，持续滋养：<br>企业文化是战略实施的土壤。识别现有文化中与战略相匹配和不匹配的因素，进行有针对性的文化建设，培育和强化支持战略执行的文化氛围（如：创新、协作、客户导向、执行力等）。这是一个长期且持续的过程，需要通过制度、培训、激励、领导示范等多种方式来推动。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 文化诊断与评估</h6>
              <p>通过问卷调查、访谈、焦点小组等方式，评估现有文化，并与理想的企业文化进行比对分析出需要优化的方面。</p>
              <p>识别现有文化中与战略相匹配和不匹配的因素。</p>
              <h6>步骤 2 (1个月): 文化建设方案设计</h6>
              <p>根据文化评估的结果，设计文化建设方案。</p>
              <p>明确文化建设的目标、原则和关键举措。</p>
              <p>制定具体的行动计划，包括制度建设、培训、激励、领导示范等。</p>
              <h6>步骤 3 (持续): 方案实施与推动</h6>
              <p>按照计划实施文化建设方案，并持续推动。</p>
              <p>通过各种方式宣传和强化期望的文化价值观和行为准则。</p>
              <h6>步骤 4 (每半年/年): 效果评估与调整</h6>
              <p>定期评估文化建设的效果，并根据实际情况进行调整。</p>
              <p>将文化建设融入到企业的日常运营中，使其成为员工的自觉行为。</p>
              <h6>衡量指标: 员工对企业文化的认同度、员工行为与期望文化的一致性、文化建设对战略执行的支持程度。</h6>

              <h3>领导层推动</h3>
              <h6>高层重视，持续推动：<br>战略的制定、执行和调整都需要领导层的高度重视和积极参与，需要领导层以身作则，持续推动和保障各项工作的落实。领导层要对战略方向进行清晰的沟通，并对执行过程进行监督和指导。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (持续): 领导层达成共识</h6>
              <p>领导层就战略方向和文化建设的重要性达成共识，并承诺积极参与和推动。</p>
              <h6>步骤 2 (持续): 以身作则</h6>
              <p>领导层在日常工作中以身作则，践行企业的使命、愿景和价值观。</p>
              <p>通过自己的言行，向员工传递战略方向和文化期望。</p>
              <h6>步骤 3 (持续): 提供支持和资源</h6>
              <p>领导层为战略执行和文化建设提供必要的支持和资源。</p>
              <p>对执行过程中遇到的困难和挑战，给予及时的指导和帮助。</p>
              <h6>步骤 4 (持续): 监督与激励</h6>
              <p>领导层对战略执行和文化建设进行监督，并对取得的成果给予积极的激励。</p>
              <h6>衡量指标: 领导层对战略的参与度、对文化建设的支持力度、员工对领导层推动力的感知程度。</h6>

              <h3>建立反馈机制</h3>
              <h6>持续改进，不断优化：<br>在战略制定、执行和调整的过程中，建立畅通的反馈机制，定期收集团队和利益相关者的意见和建议，及时发现问题并进行调整，确保战略的有效性和适应性。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周): 确定反馈渠道</h6>
              <p>建立多种反馈渠道，包括员工意见箱、内部论坛、定期调查、座谈会等。</p>
              <p>明确各渠道的负责人和处理流程。</p>
              <h6>步骤 2 (持续): 收集和整理反馈</h6>
              <p>定期收集和整理各渠道的反馈信息。</p>
              <p>对反馈信息进行分类和分析，识别关键问题和改进方向。</p>
              <h6>步骤 3 (持续): 反馈处理与改进</h6>
              <p>将反馈信息及时传达给相关部门和负责人。</p>
              <p>制定改进措施，并跟踪执行情况。</p>
              <p>向反馈者通报处理结果，形成闭环。</p>
              <h6>步骤 4 (持续): 机制优化</h6>
              <p>定期评估反馈机制的有效性，并根据实际情况进行优化。</p>
              <h6>衡量指标: 反馈渠道的畅通程度、反馈信息的数量和质量、反馈问题的解决率、员工对反馈机制的满意度。</h6>
            </dd>

            <dt style="background:#7bc495">C区：组织执行</dt>
            <div class="sub" @click="open7=!open7" :class="{fold:open7,'flex-b':!long}">优先主动</div>
            <dd :class="{open:open7||long}">
              <h3>沟通与协作</h3>
              <h6>快速消除信息不对称：<br>执行不畅往往源于沟通不畅、信息不对称。优先建立和优化沟通机制，确保信息在组织内部的快速、准确传递，可以迅速提升协同效率，减少执行偏差。强调双向沟通和基层员工反馈，及时了解执行层面的问题和建议。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (立即): 建立/优化沟通机制</h6>
              <p>确立沟通原则：开放、透明、及时、双向。</p>
              <p>选择合适的沟通工具：</p>
              <p>例会制度：建立定期（如每日站会、每周例会）的沟通机制，同步信息，协调工作。</p>
              <p>即时通讯工具：利用企业微信、钉钉等工具，方便快速沟通。</p>
              <p>项目管理软件：使用 Teambition、Asana、Trello、Jira 等项目管理工具进行任务分配、进度跟踪和协作。</p>
              <p>内部论坛/知识库：搭建内部论坛或知识库，方便员工分享经验、提问和解答。</p>
              <h6>步骤 2 (1周内): 培训与宣贯</h6>
              <p>对员工进行沟通技巧和工具使用的培训。</p>
              <p>强调沟通的重要性，并对沟通规范进行宣贯。</p>
              <h6>步骤 3 (持续): 监督与反馈</h6>
              <p>监督沟通机制的执行情况，及时发现问题并进行调整。</p>
              <p>鼓励员工积极反馈沟通中存在的问题，并进行改进。</p>
              <h6>步骤 4 (持续): 高层推动，以身作则</h6>
              <p>高层管理者要积极使用各种沟通渠道，并鼓励各级管理者及时、真诚地进行信息分享。</p>
              <h6>衡量指标: 信息传递的及时性和准确性、员工对沟通机制的满意度。</h6>
              
              <h3>领导力与管理能力</h3>
              <h6>快速提升团队战斗力：<br>领导者和管理者是执行的关键推动者。优先提升他们的领导力和管理能力，可以快速提升团队的凝聚力、战斗力和执行效率。特别是要提升他们发现问题、解决问题、激励团队以及教练辅导的能力。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1-2周): 评估与需求分析</h6>
              <p>通过 360 度评估、管理能力测评等方式，评估现有管理者的领导力和管理能力。</p>
              <p>通过访谈、问卷调查等方式，了解员工对管理者能力提升的需求。</p>
              <h6>步骤 2 (1个月): 制定发展计划</h6>
              <p>根据评估结果和需求分析，制定针对性的领导力和管理能力发展计划。</p>
              <p>可以包括培训、教练、行动学习等方式。</p>
              <p>培训内容可包括：目标设定、任务分配、有效沟通、团队激励、绩效辅导、冲突管理、问题解决等。</p>
              <h6>步骤 3 (持续): 计划实施与跟进</h6>
              <p>按照计划实施领导力和管理能力发展项目。</p>
              <p>定期跟进项目进展，并提供必要的支持和资源。</p>
              <h6>步骤 4 (每季度/半年): 效果评估与反馈</h6>
              <p>通过行为观察、绩效考核、员工反馈等方式，评估领导力和管理能力发展项目的效果。</p>
              <p>根据评估结果，调整项目内容和实施方式。</p>
              <h6>衡量指标: 管理者能力测评提升、团队绩效提升、员工满意度提升。</h6>
              
              <h3>绩效管理与激励机制</h3>
              <h6>快速激发员工积极性：<br>优化绩效考核和激励机制，可以快速激发员工的工作积极性，让员工清晰目标，主动工作。特别是短期激励的运用，以及将执行结果与绩效考核挂钩。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 优化绩效考核体系</h6>
              <p>确保绩效考核指标与组织目标一致，并具有可衡量性。</p>
              <p>将执行结果（如项目完成率、任务按时完成率等）纳入绩效考核指标。</p>
              <p>简化考核流程，提高考核效率。</p>
              <h6>步骤 2 (1周): 设计激励方案</h6>
              <p>设计短期激励方案，如项目奖金、月度/季度优秀员工奖等，及时奖励优秀表现。</p>
              <p>将激励与绩效考核结果挂钩，确保激励的公平性和有效性。</p>
              <h6>步骤 3 (1周): 沟通与宣贯</h6>
              <p>与员工充分沟通绩效考核和激励方案，确保其理解方案内容和目标。</p>
              <p>对方案进行广泛宣传，营造积极的氛围。</p>
              <h6>步骤 4 (持续): 执行与评估</h6>
              <p>按照方案执行绩效考核和激励措施，并记录执行情况。</p>
              <p>定期评估方案的效果，根据实际情况进行调整。</p>
              <h6>衡量指标: 员工积极性提升、关键绩效指标达成率、员工满意度。</h6>
              
              <h3>数据追踪与复盘</h3>
              <h6>数据为依据，持续改善：<br>建立执行力的量化评估指标，例如：项目完成率、任务按时完成率、客户满意度等。定期收集和分析执行数据，识别执行过程中的问题和瓶颈。定期进行执行复盘会议，总结经验教训，持续改进执行方案和流程。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周): 确定关键指标</h6>
              <p>确定衡量执行力的关键指标，如项目完成率、任务按时完成率、客户满意度等。</p>
              <h6>步骤 2 (1周): 建立数据收集机制</h6>
              <p>利用现有的系统或工具，建立数据收集机制，确保数据的准确性和及时性。</p>
              <p>可以利用项目管理软件、OA 系统等工具自动收集数据。</p>
              <h6>步骤 3 (持续): 数据分析与应用</h6>
              <p>定期对收集到的数据进行分析，识别执行过程中的问题和瓶颈。</p>
              <p>将数据分析结果应用于决策和改进。</p>
              <h6>步骤 4 (每季度/每月): 执行复盘会议</h6>
              <p>定期组织执行复盘会议，总结经验教训，讨论改进措施。</p>
              <p>将复盘结果落实到具体的行动计划中，并跟踪执行情况。</p>
              <h6>衡量指标: 数据收集的及时性和准确性、数据分析的有效性、复盘会议的质量、改进措施的落实率。</h6>
            </dd>
            <div class="sub" @click="open8=!open8" :class="{fold:open8,'flex-b':!long}">重要工作</div>
            <dd :class="{open:open8||long}">
              <h3>管控模式</h3>
              <h6>优化决策和监督机制：<br>选择合适的管控模式（如集权、分权、矩阵式等），建立科学的决策机制、授权体系和监督机制，确保决策的效率和执行的效果，防止失控和腐败。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 现状评估与模式选择</h6>
              <p>评估现有的管控模式及其存在的问题。</p>
              <p>根据企业的规模、业务特点和战略目标，选择合适的管控模式（如集权、分权、矩阵式等）。</p>
              <h6>步骤 2 (2周): 设计决策机制和授权体系</h6>
              <p>明确不同层级、不同岗位的决策权限。</p>
              <p>制定决策流程和审批程序。</p>
              <p>建立授权体系，明确授权的范围、条件和责任。</p>
              <h6>步骤 3 (1周): 建立监督机制</h6>
              <p>建立内部控制体系，包括内部审计、风险管理等。</p>
              <p>明确监督的职责、流程和方法。</p>
              <h6>步骤 4 (持续): 执行与优化</h6>
              <p>按照设计的管控模式、决策机制和监督机制执行。</p>
              <p>定期评估执行效果，并根据实际情况进行优化。</p>
              <h6>衡量指标: 决策效率、决策质量、风险控制水平、内部控制的有效性。</h6>

              <h3>流程制定</h3>
              <h6>提升流程效率：<br>对核心业务流程进行梳理、优化和标准化，去除冗余环节，简化流程步骤，提高流程效率和可控性，减少错误和延误。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 核心流程梳理</h6>
              <p>识别企业的核心业务流程，例如：产品开发流程、销售流程、客户服务流程等。</p>
              <p>绘制流程图，并进行流程诊断。找出每个核心业务流程的责任部门。</p>
              <h6>步骤 2 (2周): 流程优化与标准化</h6>
              <p>对流程进行优化，去除冗余环节，简化流程步骤。</p>
              <p>制定标准化的流程文件，明确每个环节的输入、输出、责任人和操作规范。</p>
              <h6>步骤 3 (1周): 流程培训与宣贯</h6>
              <p>对员工进行流程培训，确保其理解并掌握新的流程。</p>
              <p>对流程文件进行广泛宣传，确保员工能够方便地获取和使用。</p>
              <h6>步骤 4 (持续): 流程监控与改进</h6>
              <p>建立流程监控机制，定期检查流程的执行情况。</p>
              <p>根据实际情况，对流程进行持续改进。</p>
              <h6>衡量指标: 流程效率提升、流程执行的规范性、流程的持续改进能力。</h6>

              <h3>岗位体系（设计、评价、权责）</h3>
              <h6>明确职责，权责对等：<br>完善的岗位体系确保每个岗位都有清晰的职责描述、合理的权限分配和公正的绩效评估，是提升执行力的组织基础。避免职责不清、权责不明导致的推诿扯皮，确保每个员工都能高效地履行职责。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 岗位分析与设计</h6>
              <p>进行岗位分析，明确每个岗位的职责、任务和任职资格。</p>
              <p>根据岗位分析的结果，设计合理的岗位体系，避免岗位职责重叠或缺失。</p>
              <h6>步骤 2 (2周): 岗位说明书编写</h6>
              <p>编写岗位说明书，详细描述每个岗位的职责、权限、工作关系、任职资格等。</p>
              <h6>步骤 3 (1周): 岗位评估与定级</h6>
              <p>进行岗位评估，确定每个岗位的价值和等级。</p>
              <h6>步骤 4 (持续): 体系维护与更新</h6>
              <p>根据组织架构和业务的变化，及时更新岗位体系和岗位说明书。</p>
              <h6>衡量指标: 岗位职责的清晰度、权责的对等性、岗位体系的合理性、员工对岗位体系的满意度。</h6>

              <h3>制度设计</h3>
              <h6>规范行为，保障秩序：<br>建立健全各项管理制度，规范员工行为，保障企业运营的秩序。制度要清晰、合理、可执行，并得到有效监督，确保执行的一致性和公平性。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 制度梳理与评估</h6>
              <p>对现有的制度进行梳理和评估，找出需要完善或废除的制度。</p>
              <h6>步骤 2 (2周): 制度制定与修订</h6>
              <p>根据企业的实际情况，制定新的制度或修订现有的制度。</p>
              <p>确保制度的清晰性、合理性、可执行性和前瞻性。</p>
              <h6>步骤 3 (1周): 制度宣贯与培训</h6>
              <p>通过各种渠道对制度进行宣贯，确保员工了解并理解制度的内容。</p>
              <p>对员工进行制度培训，提高其执行制度的意识和能力。</p>
              <h6>步骤 4 (持续): 制度监督与改进</h6>
              <p>建立制度监督机制，确保制度得到有效执行。</p>
              <p>根据实际情况，对制度进行持续改进。</p>
              <h6>衡量指标: 制度的健全性、合理性、执行率、员工对制度的满意度。</h6>
            </dd>
            <div class="sub" @click="open9=!open9" :class="{fold:open9,'flex-b':!long}">长期行动</div>
            <dd :class="{open:open9||long}">
              <h3>组织结构</h3>
              <h6>优化组织架构：<br>根据战略目标和业务特点，设计合理的组织结构（如直线制、职能制、事业部制、矩阵制等），并明确各个部门的职责和权限。合理的组织结构能够减少部门之间的沟通成本，提高协同效率，从而提升执行力。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1个月): 现状分析与需求评估</h6>
              <p>分析现有的组织结构及其存在的问题。</p>
              <p>根据战略目标和业务特点，评估未来的组织结构需求。</p>
              <h6>步骤 2 (1个月): 组织结构设计</h6>
              <p>根据需求评估的结果，设计新的组织结构方案。</p>
              <p>明确各个部门的职责、权限和汇报关系。</p>
              <h6>步骤 3 (1个月): 方案实施与过渡</h6>
              <p>制定组织结构调整的实施方案，并进行充分的沟通。</p>
              <p>逐步实施新的组织结构，并做好人员安排和过渡工作。</p>
              <h6>步骤 4 (持续): 评估与优化</h6>
              <p>评估新组织结构的运行效果，并根据实际情况进行优化。</p>
              <h6>衡量指标: 组织结构的合理性、部门协同效率、决策效率、对战略的支持程度。</h6>

              <h3>职业发展与培训</h3>
              <h6>提升员工能力：<br>持续的培训和职业发展规划，能够不断提升员工的能力和素质，为组织的长期发展提供人才保障。包括技能培训、领导力培训、以及为员工提供清晰的职业发展路径。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (2周): 需求分析与体系设计</h6>
              <p>分析员工的职业发展需求和培训需求。</p>
              <p>设计职业发展通道和培训体系。</p>
              <h6>步骤 2 (持续): 计划实施与资源支持</h6>
              <p>制定年度培训计划，并提供必要的资源和支持。</p>
              <p>鼓励员工积极参与培训和职业发展活动。</p>
              <p>为员工提供清晰的职业发展路径和指导。</p>
              <h6>步骤 3 (每季度/半年): 效果评估与反馈</h6>
              <p>评估培训和职业发展项目的效果。</p>
              <p>收集员工反馈，优化培训和职业发展体系。</p>
              <h6>衡量指标: 员工能力提升程度、员工满意度、关键人才留任率、内部晋升比例。</h6>

              <h3>技术与工具支持</h3>
              <h6>持续的技术升级：<br>引进和应用先进的技术和工具（如OA、ERP、CRM、项目管理软件等），可以提升工作效率，简化流程，提供数据支持，帮助员工更好地完成工作，并降低运营成本。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1个月): 需求分析与方案选择</h6>
              <p>分析企业在技术和工具方面的需求。</p>
              <p>选择合适的技术和工具，如 OA、ERP、CRM、项目管理软件等。</p>
              <h6>步骤 2 (持续): 系统实施与应用</h6>
              <p>制定系统实施计划，并进行系统部署和配置。</p>
              <p>对员工进行系统使用培训，并推动系统的应用。</p>
              <h6>步骤 3 (持续): 系统维护与升级</h6>
              <p>对系统进行定期维护和升级，确保系统的稳定性和安全性。</p>
              <p>根据业务需求，不断优化系统的功能和性能。</p>
              <h6>衡量指标: 系统应用的普及率、系统运行的稳定性、工作效率的提升、成本的降低。</h6>

              <h3>执行文化</h3>
              <h6>培育执行基因：<br>执行文化的塑造是一个潜移默化的过程，需要长期坚持和引导，才能形成强大的执行基因。强调将执行文化融入到日常工作中，通过制度、流程、考核、激励等方式，将执行文化的要求具体化、可操作化；通过案例分享、故事传播等方式，宣传执行文化，营造良好的氛围。强调结果导向</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1个月): 执行文化提炼</h6>
              <p>结合企业实际，提炼执行文化的核心理念，如结果导向、责任担当、团队协作、持续改进等。</p>
              <h6>步骤 2 (持续): 文化宣贯与融入</h6>
              <p>通过各种渠道宣贯执行文化，如企业内刊、网站、宣传栏、培训等。</p>
              <p>将执行文化融入到制度、流程、考核、激励等各个方面。</p>
              <p>树立执行典范，并通过案例分享、故事传播等方式，宣传执行文化。</p>
              <h6>步骤 3 (持续): 文化评估与调整</h6>
              <p>定期评估执行文化的建设效果，并根据实际情况进行调整。</p>
              <p>将执行文化建设作为一项长期任务，持续推进。</p>
              <h6>衡量指标: 员工对执行文化的认同度、员工行为与执行文化要求的一致性、执行力相关指标的提升。</h6>
            </dd>

            <dt style="background:#78d2e0">D区：组织协作</dt>
            <div class="sub" @click="open10=!open10" :class="{fold:open10,'flex-b':!long}">优先主动</div>
            <dd :class="{open:open10||long}">
              <h3>目标明确</h3>
              <h6>统一方向，凝聚共识：<br>确保所有成员对共同目标有清晰的理解和认同，避免各自为战，减少资源浪费和方向偏差，为协作奠定基础。通过团队会议、目标分解等方式，确保每个人都清楚自己的任务目标以及与整体目标的关系。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (立即): 召开目标宣贯会议</h6>
              <p>由团队领导者主持，向所有成员清晰地传达团队的总体目标、阶段性目标以及每个人的任务目标。</p>
              <p>确保每个人都理解目标的内容、意义和衡量标准。</p>
              <p>使用 SMART 原则（具体、可衡量、可实现、相关、有时限）来确保目标的清晰度。</p>
              <h6>步骤 2 (1周内): 目标分解与确认</h6>
              <p>将团队目标分解成个人的任务目标，并与每个成员进行一对一的沟通确认。</p>
              <p>确保每个人都清楚自己的任务目标、完成时间、交付标准以及与其他成员的协作关系。</p>
              <h6>步骤 3 (持续): 目标跟踪与调整</h6>
              <p>利用项目管理工具或定期会议，跟踪目标的执行进度。</p>
              <p>根据实际情况，对目标进行必要的调整，并及时与团队成员沟通。</p>
              <h6>步骤 4 (持续): 目标视觉化</h6>
              <p>将团队的总体目标和阶段性目标进行视觉化呈现（例如：目标墙、进度看板），让所有成员随时了解目标的进展情况。</p>
              <h6>衡量指标: 团队成员对目标的理解程度、个人任务目标的清晰度。</h6>
              
              <h3>职责权力清晰</h3>
              <h6>快速定责，高效执行：<br>明确界定每个团队成员的职责、权限和汇报关系，减少职责重叠和权力真空，避免推诿扯皮，提升工作效率和决策速度。建立完善的岗位说明书和工作流程图，并定期回顾和更新。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (1周内): 制定/更新岗位说明书</h6>
              <p>制定或更新每个岗位的岗位说明书，明确岗位的职责、权限、汇报关系和任职资格。</p>
              <p>确保岗位说明书的内容具体、清晰、可操作。</p>
              <h6>步骤 2 (1周内): 绘制工作流程图</h6>
              <p>针对团队的核心业务流程，绘制工作流程图，明确每个环节的负责人和协作关系。</p>
              <p>利用泳道图等工具，清晰地展现不同岗位在流程中的职责和权限。</p>
              <h6>步骤 3 (持续): 沟通与培训</h6>
              <p>向团队成员详细解读岗位说明书和工作流程图，确保每个人都清楚自己的职责、权限和协作关系。</p>
              <p>定期组织培训，帮助团队成员理解和掌握岗位职责和工作流程。</p>
              <h6>步骤 4 (每季度/半年): 回顾与更新</h6>
              <p>定期回顾岗位说明书和工作流程图的执行情况，并根据实际情况进行更新。</p>
              <h6>衡量指标: 团队成员对自身职责和权限的清晰度、工作流程的顺畅程度。</h6>
              
              <h3>沟通</h3>
              <h6>消除壁垒，信息畅通：<br>建立开放、透明、高效的沟通机制，确保信息在团队内部的快速、准确传递，减少误解和信息不对称，促进协作顺畅。利用多种沟通渠道（如例会、邮件、即时通讯工具、项目管理平台等），并制定清晰的沟通规范。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (立即): 建立沟通机制</h6>
              <p>确定沟通的频率、方式和内容。</p>
              <p>建立例会制度（如每日站会、每周例会），同步信息，协调工作，解决问题。</p>
              <p>利用即时通讯工具（如企业微信、钉钉等）进行日常沟通。</p>
              <p>利用项目管理平台（如 Teambition、Asana、Trello 等）进行任务协作和信息共享。</p>
              <h6>步骤 2 (1周内): 制定沟通规范</h6>
              <p>制定清晰的沟通规范，明确沟通的礼仪、禁忌和技巧。</p>
              <p>例如：沟通主题要清晰、内容要简洁明了；即时通讯工具要避免闲聊和刷屏；会议要提前确定议程和时间等。</p>
              <h6>步骤 3 (持续): 培训与引导</h6>
              <p>对团队成员进行沟通技巧的培训，提升他们的沟通能力。</p>
              <p>引导团队成员积极、主动地进行沟通，并及时解决沟通中出现的问题。</p>
              <h6>步骤 4 (持续): 领导示范</h6>
              <p>领导者要以身作则，积极、主动地与团队成员进行沟通，并鼓励开放、透明的沟通氛围。</p>
              <h6>衡量指标: 信息传递的及时性和准确性、团队成员对沟通机制的满意度、沟通效率的提升。</h6>
              
              <h3>信任</h3>
              <h6>建立互信，减少内耗：<br>通过领导示范、团队建设、坦诚沟通等方式，在团队内部建立信任关系。领导者要以身作则，信守承诺，公平公正；鼓励成员之间互相了解，分享信息，表达观点，互相支持，减少猜疑和内耗，提升团队凝聚力。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (持续): 领导示范</h6>
              <p>领导者要以身作则，信守承诺，公平公正地对待每一个团队成员。</p>
              <p>主动分享信息，表达自己的观点和感受，并鼓励团队成员也这样做。</p>
              <h6>步骤 2 (1个月内): 团队建设活动</h6>
              <p>组织团队建设活动，例如：拓展训练、团队聚餐、兴趣小组等，增进团队成员之间的了解和信任。</p>
              <h6>步骤 3 (持续): 坦诚沟通与反馈</h6>
              <p>鼓励团队成员之间进行坦诚的沟通和反馈，及时解决误解和冲突。</p>
              <p>建立 1 对 1 定期沟通机制，加强了解。</p>
              <h6>步骤 4 (持续): 庆祝成功与共同面对挑战</h6>
              <p>庆祝团队的每一个成功，增强团队的凝聚力和自豪感。</p>
              <p>在面对挑战时，团队成员要互相支持，共同克服困难。</p>
              <h6>衡量指标: 团队成员之间的信任程度、团队凝聚力、团队氛围。</h6>
            </dd>
            <div class="sub" @click="open11=!open11" :class="{fold:open11,'flex-b':!long}">重要工作</div>
            <dd :class="{open:open11||long}">
              <h3>做事主动</h3>
              <h6>积极响应，推动进展：<br>培养员工的主动性，鼓励他们积极承担责任、主动发现问题、提出解决方案并推动执行。建立相应的认可和激励机制，对主动行为给予及时反馈和奖励。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (持续): 明确期望与要求</h6>
              <p>向团队成员明确传达对主动性的期望和要求。</p>
              <p>例如：鼓励主动发现问题、提出解决方案、承担责任、推动工作进展等。</p>
              <h6>步骤 2 (持续): 授权与赋能</h6>
              <p>给予团队成员适当的授权，让他们能够在职责范围内自主决策和行动。</p>
              <p>提供必要的资源和支持，帮助团队成员更好地完成工作。</p>
              <h6>步骤 3 (持续): 认可与激励</h6>
              <p>对表现出主动性的团队成员给予及时的认可和激励，例如：公开表扬、感谢信、奖金等。</p>
              <p>将主动性纳入绩效考核指标，并与晋升、加薪等挂钩。</p>
              <h6>步骤 4 (持续): 辅导与反馈</h6>
              <p>对主动性不足的团队成员进行辅导和反馈，帮助他们改进。</p>
              <p>分析其背后原因，是能力问题还是意愿问题，针对性解决。</p>
              <h6>衡量指标: 团队成员主动承担任务的比例、主动提出建议的数量、问题解决的效率。</h6>

              <h3>利他精神</h3>
              <h6>互帮互助，形成合力：<br>倡导利他精神，鼓励团队成员乐于助人、互相支持、分享知识和经验，形成团队合力。将团队合作纳入绩效考核指标，树立具有利他精神的榜样，并对他们的行为进行表彰。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (持续): 倡导与宣贯</h6>
              <p>倡导利他精神，鼓励团队成员乐于助人、互相支持、分享知识和经验。</p>
              <p>通过各种渠道宣传利他精神的重要性，例如：团队会议、内部刊物、宣传栏等。</p>
              <h6>步骤 2 (持续): 树立榜样</h6>
              <p>树立具有利他精神的榜样，并对他们的行为进行表彰和宣传。</p>
              <p>例如：评选“团队合作之星”、“最佳支持奖”等。</p>
              <h6>步骤 3 (持续): 将团队合作纳入考核</h6>
              <p>将团队合作纳入绩效考核指标，例如：帮助他人完成任务、分享知识和经验、为团队做出贡献等。</p>
              <h6>步骤 4 (持续): 营造互助氛围</h6>
              <p>通过团队建设活动、知识共享平台等方式，营造互帮互助的团队氛围。</p>
              <p>鼓励“导师制”，以老带新，互帮互助。</p>
              <h6>衡量指标: 团队成员之间互相帮助的次数、知识共享的活跃度、团队合作的满意度。</h6>
            </dd>
            <div class="sub" @click="open12=!open12" :class="{fold:open12,'flex-b':!long}">长期行动</div>
            <dd :class="{open:open12||long}">
              <h3>服务精神</h3>
              <h6>客户导向，持续改进：<br>培养内部客户服务意识，将服务精神融入日常工作，持续提升内部支持和协作的质量，营造积极的工作氛围。建立跨部门协作机制，促进部门之间的沟通和协作；将服务质量纳入绩效考核指标，推动组织长远发展。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (持续): 树立内部客户意识</h6>
              <p>引导团队成员将其他部门或同事视为内部客户，树立服务意识。</p>
              <p>强调内部服务的质量对最终客户满意度的影响。</p>
              <h6>步骤 2 (持续): 建立跨部门协作机制</h6>
              <p>建立跨部门协作机制，促进部门之间的沟通和协作。</p>
              <p>例如：建立跨部门项目小组、定期召开跨部门协调会议等。</p>
              <h6>步骤 3 (持续): 将服务质量纳入考核</h6>
              <p>将内部服务质量纳入绩效考核指标，例如：内部客户满意度、跨部门协作效率等。</p>
              <h6>步骤 4 (持续): 持续改进</h6>
              <p>定期收集内部客户的反馈意见，并根据反馈意见持续改进服务质量。</p>
              <h6>衡量指标: 内部客户满意度、跨部门协作效率、服务质量的提升。</h6>

              <h3>领导示范</h3>
              <h6>以身作则，率先垂范：<br>领导者在各个方面都要以身作则，包括目标明确、权责清晰、主动沟通、建立信任、做事主动、利他精神和服务精神。领导的示范作用对于团队文化的形成至关重要。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (持续): 言行一致</h6>
              <p>领导者在各个方面都要以身作则，包括目标明确、权责清晰、主动沟通、建立信任、做事主动、利他精神和服务精神。</p>
              <p>言行一致，说到做到，赢得团队成员的信任和尊重。</p>
              <h6>步骤 2 (持续): 积极参与</h6>
              <p>领导者要积极参与团队的工作，了解团队的运作情况，并给予必要的指导和支持。</p>
              <h6>步骤 3 (持续): 关怀团队</h6>
              <p>领导者要关心团队成员的成长和发展，并为他们提供必要的帮助和支持。</p>
              <p>营造积极向上、团结协作的团队氛围。</p>
              <h6>衡量指标: 团队成员对领导者示范作用的认可度、团队的凝聚力和向心力。</h6>

              <h3>协作机制</h3>
              <h6>构建平台，促进协作：<br>建立具体的协作机制，例如：<br>1. 定期团队会议：同步信息，协调工作，解决问题；<br>2. 跨部门协作小组：针对特定项目或任务，组建跨部门协作小组，促进部门之间的沟通和协作；<br>3. 知识共享平台：建立知识共享平台（如内部论坛、知识库等），方便团队成员分享经验和知识，提升团队的整体能力；<br>4.项目管理工具：应用项目管理工具，提升项目透明度和协作效率。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (持续): 定期团队会议</h6>
              <p>建立定期团队会议制度，例如：每日站会、每周例会、每月总结会等。</p>
              <p>同步信息，协调工作，解决问题，促进团队成员之间的沟通和协作。</p>
              <h6>步骤 2 (按需组建): 跨部门协作小组</h6>
              <p>针对特定项目或任务，组建跨部门协作小组，明确目标、职责和分工。</p>
              <p>促进部门之间的沟通和协作，提高项目执行效率。</p>
              <h6>步骤 3 (持续): 知识共享平台</h6>
              <p>建立知识共享平台（如内部论坛、知识库等），方便团队成员分享经验和知识。</p>
              <p>提升团队的整体能力和学习能力。</p>
              <h6>步骤 4 (持续): 项目管理工具</h6>
              <p>应用项目管理工具（如 Teambition、Asana、Trello、Jira 等），提升项目透明度和协作效率。</p>
              <p>将任务分解、进度跟踪、信息共享等都在平台上进行，方便团队成员协同工作。</p>
              <h6>衡量指标: 团队会议的效率、跨部门协作小组的运作效果、知识共享平台的活跃度、项目管理工具的使用率。</h6>

              <h3>认可和激励</h3>
              <h6>正向反馈，强化行为： <br>建立完善的认可和激励机制，对于团队中表现出“做事主动”、“利他精神”和“服务精神”的成员，给予及时的认可和激励（如公开表扬、奖金、晋升机会等），以强化这些行为，营造良好的协作氛围。</h6>
              <h5>实施方案（建议）：</h5>
              <h6>步骤 1 (持续): 及时认可</h6>
              <p>对于团队中表现出“做事主动”、“利他精神”和“服务精神”的成员，给予及时的认可，例如：口头表扬、感谢信、邮件表彰等。</p>
              <h6>步骤 2 (持续): 物质激励</h6>
              <p>设立与团队协作相关的奖项，例如：“最佳团队合作奖”、“最佳支持奖”等，并给予物质奖励。</p>
              <h6>步骤 3 (持续): 精神激励</h6>
              <p>将团队协作表现与晋升、加薪等挂钩，激励团队成员积极参与协作。</p>
              <h6>步骤 4 (持续): 宣传优秀案例</h6>
              <p>宣传团队协作的优秀案例，树立榜样，营造良好的协作氛围。</p>
              <h6>衡量指标: 团队成员受到认可和激励的次数、团队协作氛围的改善程度、团队绩效的提升。</h6>
            </dd>
          </dl>
        </div>
      </li>
      <!-- 职业幸福指数 -->
      <li v-if="tabId==7 || long">
        <div class="view happiness">
          <h3>职业幸福指数</h3>
          <plate :width="width*.7" :animate="!long" :data="happiness_index.index" :scale="[50,60,70,100.1]"/>
          <h4 v-if="happiness_index.index<50">团队当下的职业幸福指数<span>尚待提高</span></h4>
          <h4 v-else-if="happiness_index.index<60">团队当下的职业幸福指数<span>一般</span></h4>
          <h4 v-else-if="happiness_index.index<70">团队当下的职业幸福指数<span>较高</span></h4>
          <h4 v-else>当前团队幸福指数<span>非常好</span>，请继续保持!</h4>
          <h3  v-if="happiness_index.dim_content.length">改善建议</h3>
          <p v-for="(item,i) in happiness_index.dim_content" :key="i">{{item}}</p>
        </div>
      </li>
    </ul>
    <div class="imgBox" v-if="reportImgShow">
      <p class="text">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../../assets/images/icons/close.png"/>
    </div>
    <div class="mask" v-if="!success"/>
  </div>
</template>
<script>
import fan from '../vigour/chart/fanChart'
import target from '../vigour/chart/targetChart'
import bar from '../vigour/chart/barSvgChart'
import organize from './chart/organize'
import plate from './chart/plate.vue'
export default {
  components:{bar,target,fan,organize,plate},
  props:{
    report_id: {default: null}, //305
    version: {default: ''}
  },
  data(){
    return{
      theme: this.$route.query.theme || '',
      width: 0,
      tabId: 1,
      success: false,
      hide: this.$route.query.hide || false,                //
      hy_session: this.$route.query.hy_session || '',
      long: this.$route.query.long===undefined?false:true,  //是否报告长图
      org_name: '正在加载',                                  //企业名
      endTime: 0,                                           //报告生成成时间
      totalNum: 0,
      reportImg: '',
      reportImgShow: false,
      timer: '',
      part: ['团队基本信息、','团队画像分析、','团队风险预警、','团队活力分析、','工作价值倾向分析、','领导能量分析、','组织活力、','职业幸福指数，'],
      // 团队基本信息：
        basic_info:{
          avg_age: 0,
          educational_info: [
            {name: "高中及以下",number:0,rate:0},
            {name: "大专",number:0,rate:0},
            {name: "本科",number:0,rate:0},
            {name: "硕士研究生",number:0,rate:0},
            {name: "博士及博士后",number:0,rate:0},
          ],
          era_info: [
            {name: "25以下",number:0,rate:0},
            {name: "26~35",number:0,rate:0},
            {name: "36~45",number:0,rate:0},
            {name: "46~55",number:0,rate:0},
            {name: "55以上",number:0,rate:0},
          ],
          sex_info: [
            {name: "男性",number:0,rate:0},
            {name: "女性",number:0,rate:0}
          ],
          tier_info: [
            {name: "一线员工",number:0,rate:0},
            {name: "基层管理者",number:0,rate:0},
            {name: "中层管理者",number:0,rate:0},
            {name: "高层管理者",number:0,rate:0},
          ],
        },
      // 区域象限
        quadrant: {k:[],p:[],i:[]},
      // 团队活力度：
        team_activity: {
          consistency: null,
          dimension: [],
          top_dimension: {content:'',name:''},
          low_dimension: {content:'',name:''},
          TVI: {score: 0, level: ''},
        },
      // 原动力：
        team_motivity: {
          consistency: null,
          dimension: [],
          team_motivity: {score: 0,}
        },
      // 职业素质:
        team_quality: {
          consistency: null,
          dimension: [],
          team_quality: {score: 0}
        },
      // 领导能量：
        team_leadership: {
          show: false,
          dimension: [],
          leadership: {score: 0,level: ''},
          low_dimension: [],
          top_dimension: [],
        },
      // 工作价值倾向：
        team_workvalue:{
          consistency: null,
          dimension: [],
          top_dimension: ['','',''],
          wordText: [ [], [], [], [], ],//高中低维度
        },
      // 团队风险：
        team_risk: [],
        riskOpen: [],
      // 组织活力：
        organize: {
          dimension: [], 
          dimension_region: [{score:0}, {score:0}, {score:0}, {score:0}]
        },
      // 职业幸福指数
        happiness_index: {
          index: '',
          content: '',
          dim_content: []
        },
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      open6: false,
      open7: false,
      open8: false,
      open9: false,
      open10: false,
      open11: false,
      open12: false,
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Tvs/getTeamReportInfo?team_report_id='+this.report_id+'&is_share=1').then((e) =>{
      this.$indicator.close();
      let data = e.data.data
      if (e.data.code == 0) {
        this.success = true
        // 团队基本信息：
          this.org_name = data.team_info.org_name
          this.endTime = data.team_info.report_time
          this.totalNum = data.team_info.total_number
          this.basic_info = data.basic_info
        // 风险：
          this.team_risk = data.team_risk.filter(it => it.is_show)
          for(let i in this.team_risk) this.riskOpen[i] = {A:0,B:0,C:0}
          if (this.team_risk.length == 0) this.part.splice(this.part.indexOf('团队风险预警、'), 1)
        // 区域象限：
          this.quadrant = data.quadrant
        // 团队活力度：
          let activity = data.team_activity
          activity.consistency = {4: activity.consistency}  //key只为控制折线颜色
          this.team_activity = activity

        // 原动力：
          this.team_motivity = data.team_motivity
        // 职业素质：
          this.team_quality = data.team_quality
        // 领导能量：
          if (data?.team_leadership.leadership) {
            this.team_leadership = data.team_leadership
            this.team_leadership.show = true
          } else {
            this.part.splice(this.part.indexOf('领导能量分析、'), 1)
          }
        // 工作价值倾向：
          let workvalue = data.team_workvalue, wordText = [[],[],[],[]]
          for(let i in workvalue.consistency){
            if(workvalue.consistency[i].dispersion>0.8){ //低
              wordText[3].push(workvalue.consistency[i].name)
            }else if(workvalue.consistency[i].dispersion>.3){ //中等
              wordText[2].push(workvalue.consistency[i].name)
            }else if(workvalue.consistency[i].dispersion>.15){ //中上
              wordText[1].push(workvalue.consistency[i].name)
            }else //高
              wordText[0].push(workvalue.consistency[i].name)
          }
          workvalue.wordText = wordText
          workvalue.consistency = {5: workvalue.consistency}  //key只为控制折线颜色
          this.team_workvalue = workvalue
        // 组织活力：
          this.organize = data.organizational_vitality_model
        // 职业幸福指数：
          this.happiness_index = data.happiness_index
      } else {
        this.org_name = '无法加载'
        this.$toast({message:'加载失败',duration: 2000})
      }
    }).catch((err)=>{
      this.org_name = '无法加载'
      this.success = false
      this.$indicator.close();
      console.error(err);
      if (err.message.indexOf('org_name') > -1) {//code=0接口报错
        this.$toast({message:'报告错误，请联系管理员',duration: 5000})
      } else {
        this.$toast({message:'网络错误',duration: 1500})
      }
    })
  },
  mounted(){
    this.width = document.getElementById('report').clientWidth
  },
  methods:{
    tab(e){
      this.$refs.barRow1.showIndex = null
      if (this.tabId == e) return;

      this.tabId = e;
      for(let i in this.team_risk) this.riskOpen[i] = {A:0,B:0,C:0}
    },
    expand(i, k){
      this.riskOpen[i][k] = !this.riskOpen[i][k]
      this.$forceUpdate()
    },
    save(){
      // if (this.ajaxUrlPath.indexOf('app')>-1){
        // this.long = true; return;
      // }
      if (this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.spaceUrl+'/Tvs/getTeamReportImage?team_report_id='+this.report_id+'&hy_session='+this.hy_session).then((e) =>{
          if (e.data.code == 0) {//已保存了长图
            this.reportImg = e.data.data
            this.reportImgShow = true
          } else {//新生成长图
            var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long&ver=' + this.version
            this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'})
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg', {
              type: 'tvsTeamReport',
              imageurl: url,
              width: '500',
              height: '1000',
            }).then(e => {
              this.$indicator.close()
              if (e.data.code == 0) {
                this.reportImg = e.data.data.image
                this.reportImgShow = true
                //保存当前报告的图片
                // this.$http.post(this.spaceUrl+'/Tvs/updateTeamReportImage?hy_session=' + this.hy_session,{
                //   team_report_id: this.report_id,
                //   report_image: this.reportImg,
                // })
              } else {
                this.$toast({message: e.data.msg})
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
      this.timer && clearInterval(this.timer)
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#666;
  box-shadow: 0 0 10px rgba(200,200,200,.6);
  padding-bottom: 40px;
  min-height: 100%;
  position: relative;
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    color: #808080;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      padding: 0 40px;
      height: 120px;
      justify-content: space-between;
      line-height: 1.1;
      .label{
        text-align: left;
        padding-right: 20px;
        font-size: 24px;
        line-height: 1.5;
        b{font-size: 26px}
      }
      .btn{
        width: 130px;
        color: #fff;
        border-radius: 6px;
        background: #2384ff;
        line-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        padding: 0 10px;
        flex-shrink: 0;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
    nav{
      display: flex;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      box-sizing: border-box;
      font-size: 24px;
      align-items: center;
      span{
        flex: 1;
        line-height: 1.2;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #f0f0f0;
        width: 25%;
        box-sizing: border-box;
        transition: .1s;
        &:last-child{border: none}
      }
      .current{
        color: #4d4d4d;
        font-weight: bold;
        background: #cdebff;
        color: #2384ff;
      }
    }
  }
  .content{
    overflow-x: hidden;
    .TVI{
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 50px;
      color: #424242;
    }
    li{
      margin: 0 30px;
      .orange{
        color: #ee7e2e;
        font-size: 28px;
        text-align: left;
        margin: 40px 0 18px 24px;
      }
      h1{
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        margin: 20px 0;
        color: #5c5c5c;
      }
      h3{
        font-size: 28px;
        margin: 40px 0;
        color: #0072ff;
        font-weight: bold;
      }
      p{
        padding-left: 24px;
        text-align: justify;
        font-size: 26px;
        position: relative;
        line-height: 1.5;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 14px;
        }
      }
      .text{
        text-align: justify;
        font-size: 26px;
        color: #6f6f6f;
        line-height: 1.6;
        b{color: #dd331e}
      }
      .high span{color: #3fb3ff;}
      .high::before{background: #3fb3ff;}
      .low span{color: #e86c52;}
      .low::before{background: #e86c52;}
      .blue{color: #3fb3ff}
      .yellow{color: #ebb748}
      .red{color: #f8887b}
      .green{color: #88b56c}
    }
    .view{
      margin: 30px 0;
      padding: 1px 20px 60px;
      border-radius: 10px;
    }
    .explain{
      .view div{
        text-align: justify;
        font-size: 26px;
        line-height: 1.5;
      }
      b{color: #dd331e;}
    }
    .message{
      font-size: 24px;
      color: #808080;
      border: 1px solid #6ec6fd;
      margin: 40px 0;
      &>div{display: flex;}
      .above{
        border-bottom: 1px dashed #6ec6fd;
        &>div:nth-child(1){
          border-right: 1px dashed #6ec6fd;
          h5{width: 100px}
          .number{margin-left: 50px}
        }
        &>div:nth-child(2){
          h5{width: 144px}
        }
      }
      .under{
        &>div:nth-child(1){
          border-right: 1px dashed #6ec6fd;
          h5{width: 150px}
        }
        &>div:nth-child(2){
          h5{width: 120px}
          .number{margin-left: 42px;}
        }
      }
      .box{
        box-sizing: border-box;
        padding: 10px 0 40px;
        width: 50%;
        position: relative;
        .line{
          display: flex;
          line-height: 24PX;
          height: 24PX;
          margin: 16px 0;
          svg{
            flex-shrink: 0;
            margin: -2px 10px 0 12px;
          }
        }
        h4{
          font-size: 26px;
          width: 160px;
          background: #3fb3ff;
          margin: 20px auto 40px;
          border-radius: 45px;
          color: #fff;
          line-height: 2;
        }
        h5{
          text-align: right;
          white-space: nowrap;
          font-size: 24px;
        }
        h6{
          white-space: nowrap;
          font-size: 24px;
        }
        .number{
          text-align: left;
          font-size: 24px;
        }
      }
    }
    .messageMini .box .line svg{//小屏的团队基本信息缩小间距
      margin-top: -2px;
    }
    .teamRisk{
      img{
        display: block;
        width: 180px;
        margin: 40px auto;
      }
      .txt{
        margin: 30px 0;
        b{color: #dd331e;}
      }
      .text b{color: #414b5e;}
    }
    .word{
      padding-left: 30px;
      margin: 40px 0;
      div{
        &:nth-child(1){
          font-weight: bold;
          color: #dd331e;
          line-height: 2;
          font-size: 28px;
        }
        font-size: 26px;
        text-align: justify;
      }
      .risk{
        position: relative;
        line-height: 1.5;
        &::before{position: absolute;content: '';}
        b{color: #545454;}
      }
      .risk1::before{
        background: url('../../../assets/images/report/risk.png') no-repeat;
        background-size: 100%;
        width: 28px;
        height: 28px;
        top: 4px;
        left: -36px;
      }
      .risk2::before{
        background: linear-gradient(to right,#ffcdc7 50%,#dd331e 50%);
        border-radius: 50%;
        width: 22px;
        height: 22px;
        top: 8px;
        left: -32px;
      }
      .risk3::before{background: #dd331e}
    }
    .area{
      margin: 20px 40px 50px;
      & > div{
        display: flex;
        margin: 30px 0;
        & > div{
          flex: 1;
          h5:nth-child(1){border-bottom: none}
        }
        h4{
          min-height: 210px;
          color: #fff;
          padding: 0 20px;
          div{
            width: 20px;
            height: 20px;
            margin: 6px auto;
            transform: translateY(76px);
          }
          img{width: 100%;}
          b{
            display: block;
            font-size: 26px;
            transform: translateY(76px);
          }
        }
        h5{
          box-sizing: border-box;
          min-height: 105px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          text-align: justify;
          line-height: 1.3;
          padding:10px 0 10px 24px;
          flex-wrap: wrap;
          border-left: none;
          span{
            font-size: 24px;
            margin-right: 20px;
          }
        }
        
        &:nth-child(1){
          h4{background: #e96842}
          h5{border-color: #e96842;color: #e96842}
        }
        &:nth-child(2){
          h4{background: #42b2fc}
          h5{border-color: #42b2fc;color: #42b2fc}
        }
        &:nth-child(3){
          h4{background: #7886f0}
          h5{border-color: #7886f0;color: #7886f0}
        }
        &:nth-child(4){
          h4{background: #28c8b7}
          h5{border-color: #28c8b7;color: #28c8b7}
        }
      }
    }
    .cognition{
      width: 624px;
      margin: 70px auto;
      & > div{
        width: 50%;
        border-top: 12px solid #ccc;
        border-bottom: 12px solid #ccc;
        height: 180px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-right: 24px solid #ccc;
        }
        &::after{
          content: '';
          position: absolute;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-left: 24px solid #ccc;
        }
        &:nth-child(1){
          border-color: #3db5ff;
          border-left: 12px solid #3db5ff;
          border-top-left-radius: 90px;
          border-bottom-left-radius: 90px;
          &::before{
            border-right: 24px solid #3db5ff;
            top: -24px;
            right: 0;
          }
          &::after{
            border-left: 24px solid #3db5ff;
            bottom: -24px;
            right: 100px;
          }
          .cricle{
            background: #3db5ff;
            span{
              left: 108%;
              color: #3db5ff;
            }
          }
        }
        &:nth-child(2){
          border-color: #ee7e2e;
          border-right: 12px solid #ee7e2e;
          border-top-right-radius: 90px;
          border-bottom-right-radius: 90px;
          margin: -12px 0 0 50%;
          &::before{
            border-right: 24px solid #ee7e2e;
            bottom: -24px;
            left: -24px;
          }
          &::after{
            border-left: 24px solid #ee7e2e;
            top: -24px;
            left: 100px;
          }
          .cricle{
            background: #ee7e2e;
            float: right;
            span{
              right: 108%;
              color: #ee7e2e;
            }
          }
        }
        .cricle{
          width: 136px;
          height: 136px;
          line-height: 136px;
          border-radius: 50%;
          margin: 10px;
          background: #ccc;
          position: relative;
          color: #fff;
          font-size: 25px;
          span{
            line-height: 32px;
            position: absolute;
            width: 430px;
            top: 50%;
            transform: translateY(-50%);
            text-align: left;
          }
        }
      }
    }
    .org {
      p{
        color: #2081a4;
        &::before{
          width: 16px;
          height: 16px;
          background: #f77c03;
          left: 0;
          top: 10px;
        }
      }
      dl{
        .sub{
          padding: 0 20px;
          line-height: 100px;
          margin: 0;
          position: relative;
          background: #e2e9fc;
          border-top: 2px solid rgba(255,255,255,.8);
          color: #5e80df;
          &::before{border-color: #5e80df;}
          &:nth-of-type(4),&:nth-of-type(5),&:nth-of-type(6){
            background: #ebe7f3;
            color: #ac84f0;
            &::before{border-color: #ac84f0;}
            &+dd h3{color: #ac84f0;}
          }
          &:nth-of-type(7),&:nth-of-type(8),&:nth-of-type(9){
            background: #ecf5ee;
            color: #6bb279;
            &::before{border-color: #6bb279;}
            &+dd h3{color: #6bb279;}
          }
          &:nth-of-type(10),&:nth-of-type(11),&:nth-of-type(12){
            background: #eef9ff;
            color: #4692b2;
            &::before{border-color: #4692b2;}
            &+dd h3{color: #4692b2;}
          }
        }
        h3{margin: 20px 0;}
        h5{color: #414b5e;margin: 20px 0;}
        h6{margin-top: 20px;}
        p{
          color: #414b5e;
          &::before{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #414b5e;
            left: 4px;
            top: 14px;
          }
        }
        .open{max-height: 5400px}
      }
    }
    .happiness h4{
      color: #414b5e;
      margin: 30px 0 80px;
      font-size: 28px;
      span{color: #2da9f7}
    }
      dl{
        text-align: left;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 80px;
        box-shadow: 0 0 8px rgba(200,200,200,.2);
        dt{
          background: #7a99f1;
          color: #fff;
          font-size: 26px;
          padding: 0 20px;
          line-height: 100px;
          position: relative;
        }
        .flex-b::after{
          content: '展开';
          font-size: 24px;
          margin-right: 50px;
        }
        .flex-b::before{
          content: '';
          position: absolute;
          right: 30px;
          top: 36px;
          width: 14px;
          height: 14px;
          border-radius: 2px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(45deg);
          transform-origin: center 60%;
          transition: .2s;
        }
        .fold::after{content: '收起'}
        .fold::before{transform: rotate(-135deg) translateX(-6px);}
        dd{
          background: rgba(255,255,255,.8);
          font-size: 26px;
          padding: 0 20px;
          overflow: hidden;
          max-height: 0;
          transition: .6s linear;
          &::after{content: '';display: block;height: 40px;}
          h4{
            color: #4c71db;
            font-weight: bold;
            padding-top: 40px;
          }
          h5{
            margin-top: 30px;
            color: #4c71db;
            font-weight: bold;
          }
          h6{
            color: #414b5e;
          }
        }
        .open{max-height: 2000px}
      }
  }
  .imgBox{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 100;
      font-size:30px;
      .text{
        line-height: 100px;
        color:#fff;font-size:26px;
      }
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .rank{
    position: relative;
    width: 388px;
    height: 380px;
    margin: 0px auto 10px;
    color: #fff;
    font-size: 26px;
    .box{
      position: absolute;
      box-sizing: border-box;
      border-radius: 50%;
      border: 6px solid #f2f2f2;
      line-height: 1.6;
      font-weight: bold;
      top: 50%;
      span{display: block;font-weight: normal}
    }
    .box1{
      width: 240px;
      height: 240px;
      padding-top: 80px;
      margin-top: -120px;
      left: -120px;
      background: #3fb3ff;
      animation: box .6s forwards;
    }
    .box2{
      width: 200px;
      height: 200px;
      margin-top: -100px;
      padding-top: 60px;
      left: 136px;
      background: rgba(78,200,183,0.9);
      animation: box 1s forwards;
    }
    .box3{
      width: 160px;
      height: 160px;
      margin-top: -80px;
      padding-top: 40px;
      left: 350px;
      background: rgba(237,125,49,0.9);
      animation: box 1.4s forwards;
    }
    @keyframes box {
      0%{opacity: 0;transform: translateY(40px)}
      100%{opacity: 1;transform: translateY(0)}
    }
  }
}

.planet{
  background-color: #2da8ff;
  background-image: url('../../../../static/img/planet_bg14.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top{
    color: #fff;
    nav{
      color: #fff;
      border-color: #fff;
      .current{
        background: #c0e9fa;
        color: #414b5e;
      }
    }
  }
  .content{
    .view{
      background: rgba(255,255,255,.8);
    }
    .explain, li .text, li p, li h1{color: #414b5e}
    .word{
      div{color: #414b5e}
      .risk b{color: #414b5e}
    }
  }
}
</style>