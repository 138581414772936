<template>
  <!--报告-->
  <group_1_0 v-if="version=='1.0.0'" :version="version" :report_id="report_id"/>
  <group_1_1 v-else-if="version=='1.1.0'" :version="version" :report_id="report_id"/>
  <group_1_2 v-else-if="version=='1.2.0'" :version="version" :report_id="report_id"/>
  <group_1_3 v-else :version="version" :report_id="report_id"/>
</template>

<script>
import group_1_0 from './group1.0.vue'
import group_1_1 from './group1.1.vue'
import group_1_2 from './group1.2.vue'
import group_1_3 from './group1.3.vue'
export default {
  components:{group_1_0,group_1_1,group_1_2,group_1_3},
  data(){
    return{
      report_id: this.$route.params.report_id || '',
      version: this.$route.query.ver || '',
    }
  },
  mounted(){
    if (!this.version)
      this.$http.get(this.spaceUrl+`/Tvs/getTeamReportVersion?team_report_id=${this.report_id}`).then(({data}) => {
        if (data.code == 0) {
          this.version = data.data.version
        } else {
          this.$toast({message:'无法加载',duration: 2000})
          console.error(data.msg)
        }
      })
  },
}
</script>
