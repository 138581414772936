import { render, staticRenderFns } from "./group1.2.vue?vue&type=template&id=d8d2047a&scoped=true"
import script from "./group1.2.vue?vue&type=script&lang=js"
export * from "./group1.2.vue?vue&type=script&lang=js"
import style0 from "./group1.2.vue?vue&type=style&index=0&id=d8d2047a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8d2047a",
  null
  
)

export default component.exports