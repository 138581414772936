<template>
  <div class="topSaleReport" @scroll="scroll">
    <header>
      <img :src="avatar">
      <div>
        <div><b>{{name}}</b>{{time ? ' 的报告' : ''}}</div>
        <div class="time">{{$formatTime(time,'y-m-d h:m')}}</div>
      </div>
    </header>
    <div class="tabBox" id="tabBox">
      <ul class="flex" :class="{fix:fixed}" id="tab">
        <li :class="{active:tabIndex==1}" @click="changeTab(1)">综合说明</li>
        <li :class="{active:tabIndex==2}" @click="changeTab(2)">心态管理</li>
        <li :class="{active:tabIndex==3}" @click="changeTab(3)">目标管理</li>
        <li :class="{active:tabIndex==4}" @click="changeTab(4)">拜访活动</li>
        <li :class="{active:tabIndex==5}" @click="changeTab(5)">销售面谈</li>
        <li :class="{active:tabIndex==6}" @click="changeTab(6)">答题结果分析</li>
      </ul>
    </div>
    <!-- 综合说明 -->
    <section v-if="tabIndex==1"><!-- if、show不可随便更改 -->
      <p>{{saleData.description}}</p>
      <sector :data="saleData.score" :refer="81"/>
      <div class="sectorTxt" >{{saleData.level_tips}}</div>
      <barLine :data="saleBar" :animate="true"/>
      <h1>优势领域</h1>
      <p>{{saleData.advantage_content}}</p>
      <h1>改善领域</h1>
      <p class="orange">{{saleData.improve_content.title}}：</p>
      <p v-for="(it,i) in saleData.improve_content.content" :key="i" class="dot"><span class="orange">{{it.name}}：</span>{{it.text}}</p>
      <h1>持续学习方向</h1>
      <p class="orange">{{saleData.learning_content.title}}：</p>
      <p v-for="(it,i) in saleData.learning_content.content" :key="'p'+i" class="dot">{{it}}</p>
    </section>
    <!-- 心态管理 -->
    <section v-show="tabIndex==2">
      <p>{{mindData.description}}</p>
      <singleBar :data="mindData.score" :refer="81" :text="mindData.level_tips"/>

      <h1>心态管理的作用</h1>
      <p class="orange">心态管理包含：</p>
      <p>①面对销售工作的积极心态、②面对突发状况的灵活应变、③对销售工作难度的接受心态、④面对逆境时抵御压力的心态。</p>
      <div v-for="(item,i) in mindData.useful" :key="'p2'+i" :class="{boxOpen:item.opened, boxWhite:bgIndex===i}" @click="open('mindData.useful', i)" @touchstart="touch('mindData.useful', i)">
        <p class="orange">{{no[i]}}{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p3'+j" class="dot" v-show="j==0||item.opened">
          <span class="orange">{{it.name}}：</span>{{it.text}}
          <label v-if="j==0">展开全部</label>
        </p>
      </div>

      <h1>如何跨过4 NO的挑战？</h1>
      <div v-for="(item,i) in mindData.challenge_data" :key="'p'+i">
        <p class="orange">{{item.title}}</p>
        <p class="dot" v-for="(it,j) in item.content" :key="'p4'+i+j">
          <span class="orange">{{it.name}}：</span>{{it.text}}
        </p>
        <p class="teacher" @click="openSuggest(item.suggestion)">销售教练建议你这样做 <span>点击查看</span></p>
      </div>

      <h1>BEE的作用<br>(信念、热情、共情)</h1>
      <div class="box1p" :class="{boxOpen:mindData.mindData_bee, boxWhite:bgIndex=='mindData_bee'}" @click="open('mindData', 'mindData_bee')" @touchstart="touch('mindData', 'mindData_bee')">
        <p v-for="(it,i) in mindData.bee" :key="'p7'+i" class="dot" v-show="i==0||mindData.mindData_bee">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
    </section>
    <!-- 目标管理 -->
    <section v-show="tabIndex==3">
      <p>{{goalData.description}}</p>
      <singleBar :data="goalData.score" :refer="83" :text="goalData.level_tips"/>
      
      <h1>目标管理的作用</h1>
      <p class="orange">目标管理包含：</p>
      <p>①目标驱动、②对每个阶段的重视度、③对达成目标的规划仔细度、④保证成果的心态和动作。</p>
      <div v-for="(item,i) in goalData.useful" :key="'p2'+i" :class="{boxOpen:item.opened, boxWhite:bgIndex===i}" @click="open('goalData.useful', i)" @touchstart="touch('goalData.useful', i)">
        <p class="orange">{{no[i]}}{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p3'+j" class="dot" v-show="j==0||item.opened">
          <span class="orange">{{it.name}}：</span>{{it.text}}
          <label v-if="j==0">展开全部</label>
        </p>
      </div>

      <h1>如何跨过4 NO的挑战？</h1>
      <div v-for="(item,i) in goalData.challenge_data" :key="'p'+i">
        <p class="orange">{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p4'+i+j" class="dot">
          <span class="orange">{{it.name}}：</span>{{it.text}}
        </p>
        <p class="teacher" @click="openSuggest(item.suggestion)">销售教练建议你这样做 <span>点击查看</span></p>
      </div>
      
      <h1>BEE的作用<br>(信念、热情、共情)</h1>
      <div class="box1p" :class="{boxOpen:goalData.goalData_bee, boxWhite:bgIndex=='goalData_bee'}" @click="open('goalData', 'goalData_bee')" @touchstart="touch('goalData', 'goalData_bee')">
        <p v-for="(it,i) in goalData.bee" :key="'p7'+i" class="dot" v-show="i==0||goalData.goalData_bee">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
    </section>
    <!-- 拜访活动 -->
    <section v-show="tabIndex==4">
      <p>{{meetData.description}}</p>
      <singleBar :data="meetData.score" :refer="83" :text="meetData.level_tips"/>

      <h1>拜访活动的作用</h1>
      <p class="orange">拜访活动包含：</p>
      <p>①拜访行程的规划（合理、有序）、②拜访工作前/中段的准备程度、③对自我的管理（行程安排/拜访时间/客户资料收集等）、④对拜访活动的期待程度。</p>
      <div v-for="(item,i) in meetData.useful" :key="'p2'+i" :class="{boxOpen:item.opened, boxWhite:bgIndex===i}" @click="open('meetData.useful', i)" @touchstart="touch('meetData.useful', i)">
        <p class="orange">{{no[i]}}{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p3'+j" class="dot" v-show="j==0||item.opened">
          <span class="orange">{{it.name}}：</span>{{it.text}}
          <label v-if="j==0">展开全部</label>
        </p>
      </div>

      <h1>如何跨过4 NO的挑战？</h1>
      <div v-for="(item,i) in meetData.challenge_data" :key="'p'+i">
        <p class="orange">{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p4'+i+j" class="dot">
          <span class="orange">{{it.name}}：</span>{{it.text}}
        </p>
        <p class="teacher" @click="openSuggest(item.suggestion)">销售教练建议你这样做 <span>点击查看</span></p>
      </div>
      
      <h1>BEE的作用<br>(信念、热情、共情)</h1>
      <div class="box1p" :class="{boxOpen:meetData.meetData_bee, boxWhite:bgIndex=='meetData_bee'}" @click="open('meetData', 'meetData_bee')" @touchstart="touch('meetData', 'meetData_bee')">
        <p v-for="(it,i) in meetData.bee" :key="'p7'+i" class="dot" v-show="i==0||meetData.meetData_bee">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
    </section>
    <!-- 销售面谈 -->
    <section v-show="tabIndex==5">
      <p>{{talkData.description}}</p>
      <singleBar :data="talkData.score" :refer="77" :text="talkData.level_tips"/>
      
      <h1>销售面谈的作用</h1>
      <p class="orange">销售面谈包含：</p>
      <p>①产品的熟悉度（知识/功能/利益等）、②说明技巧/话术、③与客户面谈时间的合理度、④和客户良好互动的程度、⑤对客户问题的理解度、⑥把握局面的能力。</p>
      <div v-for="(item,i) in talkData.useful" :key="'p2'+i" :class="{boxOpen:item.opened, boxWhite:bgIndex===i}" @click="open('talkData.useful', i)" @touchstart="touch('talkData.useful', i)">
        <p class="orange">{{no[i]}}{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p3'+j" class="dot" v-show="j==0||item.opened">
          <span class="orange">{{it.name}}：</span>{{it.text}}
          <label v-if="j==0">展开全部</label>
        </p>
      </div>

      <h1>如何跨过4 NO的挑战？</h1>
      <div v-for="(item,i) in talkData.challenge_data" :key="'p'+i">
        <p class="orange">{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p4'+i+j" class="dot">
          <span class="orange">{{it.name}}：</span>{{it.text}}
        </p>
        <p class="teacher" @click="openSuggest(item.suggestion)">销售教练建议你这样做 <span>点击查看</span></p>
      </div>
      
      <h1>BEE的作用<br>(信念、热情、共情)</h1>
      <div class="box1p" :class="{boxOpen:talkData.talkData_bee, boxWhite:bgIndex=='talkData_bee'}" @click="open('talkData', 'talkData_bee')" @touchstart="touch('talkData', 'talkData_bee')">
        <p v-for="(it,i) in talkData.bee" :key="'p7'+i" class="dot" v-show="i==0||talkData.talkData_bee">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
    </section>
    <!-- 答题结果分析 -->
    <section v-show="tabIndex==6" style="padding-bottom: 0;overflow: hidden;">
      <div class="question" :class="{quesOpen: ques.opened, boxWhite:bgIndex==idx}" @click="openAnswer(idx, true)" v-for="(ques,idx) in answerData" :key="idx">
        <h2>{{idx+1}}. {{ques.question_title}}<label @click.stop="openAnswer(idx)"/></h2>
        <h3 class="flex--c"><span>{{ques.option_key}}</span>{{ques.option_content}}</h3>
        <div class="word">
          <!-- 选项说明 -->
          <h4 class="flex-c-c">选项<span>说明</span></h4>
          <template v-if="ques.explain_data.length">
            <p class="dot" v-for="(t,i) in ques.explain_data" :key="'e'+i">{{t}}</p>
          </template>
          <template v-else>
            <template v-for="(it,key,index2) in ques.explain_data">
              <p class="orange" :key="key"><span>{{no[index2]}}</span> {{key}}</p>
              <p class="dot" v-for="(e,j) in it" :key="key+j">{{e}}</p>
            </template>
          </template>
          <!-- 销售教练 -->
          <h4 class="flex-c-c">销售<span>教练</span></h4>
          <p class="dot" v-for="(e,j) in ques.sales_text" :key="j">{{e}}</p>
        </div>      
      </div>
    </section>
    <p class="ver">Ver {{version}}</p>

    <div class="mask" v-if="popupShow">
      <div class="popup">
        <img src="../../../assets/images/icons/close_2.png" @click="popupShow=false">
        <div class="container">
          <h6>{{popupData.title}}</h6>
          <div v-for="(it1,k1) in popupData.content" :key="k1">
            <p class="orange martop">{{k1}}</p>
            <div v-for="(it2,k2) in it1" :key="k2">
              <p class="dot mar1" v-if="typeof(it2)=='string'">{{it2}}</p>
              <template v-else>
                <p class="orange">{{k2}}</p>
                <div v-for="(it3,k3) in it2" :key="k3">
                  <p class="dot mar1" v-if="typeof(it3)=='string'">{{it3}}</p>
                  <template v-else>
                    <p class="orange">{{k3}}</p>
                    <div v-for="(it4,k4) in it3" :key="k4">
                      <p class="dot mar1" v-if="typeof(it4)=='string'">{{it4}}</p>
                      <template v-else>
                        <p class="orange">{{k4}}</p>
                        <div v-for="(it5,k5) in it4" :key="k5">
                          <p class="dot mar1" v-if="typeof(it5)=='string'">{{it5}}</p>
                          <template v-else>
                            <p class="orange">{{k5}}</p>
                            <p class="dot mar1" v-for="(it6,k6) in it5" :key="k6">{{it6}}</p>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sector from './chart/sector'
import barLine from './chart/barLine'
import singleBar from './chart/singleBar'
export default {
  components: {sector,barLine,singleBar},
  props:{
    exam_id: {default: null},
    hy_session: {default: null},
    version: {default: '1.0'}
  },
  data(){
    return {
      // exam_id: this.$route.query.exam_id || '3762',
      time: '',
      name: '',
      avatar: require('../../../assets/images/icons/user_icon.png'),

      tabIndex: 1,
      tabTop: 0,
      Tab: null,
      fixed: false,
      bgIndex: '',  //白色背景显示(数字/字符)
      no: ['①','②','③','④','⑤','⑥','⑦','⑧','⑨','⑩','⑪','⑫','⑬','⑭','⑮'],

      saleData: {
        description: '',
        score: 0,
        level_tips: '',
        advantage_content: '',
        improve_content: {title:'',content:[]},
        learning_content: {title:'',content:[]},
      },
      saleBar: [],  //综合说明横向条形图
      mindData: {
        description: '',        //描述
        score: 0,               //分数
        level_tips: '',         //等级文字
        useful: [],             //作用
        best_suggestion: [],    //最佳行动建议
        challenge_data: [],     //4NO
        bee: [],
      },
      goalData: {
        description: '',        //描述
        score: 0,               //分数
        level_tips: '',         //等级文字
        useful: [],             //作用
        best_suggestion: [],    //最佳行动建议
        challenge_data: [],     //4NO
        bee: [],
      },
      meetData: {
        description: '',        //描述
        score: 0,               //分数
        level_tips: '',         //等级文字
        useful: [],             //作用
        best_suggestion: [],    //最佳行动建议
        challenge_data: [],     //4NO
        bee: [],
      },
      talkData: {
        description: '',        //描述
        score: 0,               //分数
        level_tips: '',         //等级文字
        useful: [],             //作用
        best_suggestion: [],    //最佳行动建议
        challenge_data: [],     //4NO
        bee: [],
      },
      answerData: [],
      popupShow: false,
      popupData: {},
    }
  },
  created() {
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.ajaxUrlPath+'/TopSale/getExamReportInfo',{
      params:{exam_id: this.exam_id}
    }).then(e => {
      this.$indicator.close()
      if (e.data.code == 0) {
        //个人信息
        let data = e.data.data
        this.avatar = data.user_info.avatar
        this.name = data.user_info.realname
        this.time = data.user_info.end_time

        this.saleData = data.sale_data
        this.saleBar = [
          {name: data.mind_data.name, score: data.mind_data.score, refer: 79},
          {name: data.goal_data.name, score: data.goal_data.score, refer: 83},
          {name: data.meet_data.name, score: data.meet_data.score, refer: 83},
          {name: data.talk_data.name, score: data.talk_data.score, refer: 77},
        ]
        this.mindData = data.mind_data
        this.goalData = data.goal_data
        this.meetData = data.meet_data
        this.talkData = data.talk_data
        this.answerData = data.answer_data || []
      } else {
        this.name = '无法加载'
        this.$toast({message: e.data.msg, duration: 200000})
      }
    }).catch((err) => {
      console.error(err)
      this.name = '加载失败'
      this.$toast({message: '网络错误', duration: 1500})
      this.$indicator.close()
    })
  },
  mounted(){
    this.tabTop = $(".tabBox").offset().top
    this.Tab = document.getElementById('tab')
  },
  methods: {
    changeTab(next) {
      if (this.tabIndex == next) return
      if (this.fixed) this.$el.scrollTop = this.tabTop  //滚动到每页顶部
      this.tabIndex = next
      this.bgIndex = ''
      
      let { offsetLeft, offsetWidth } = event.target
      this.Tab.scrollTo({behavior: 'smooth', left: offsetLeft - this.Tab.clientWidth/2 + offsetWidth/2})
    },
    open(s, i) {
      if (this.bgIndex === i) return
      this.bgIndex = i
      let key = s.split('.')
      if (key[1]) {
        this.$set(this[key[0]][key[1]][i], 'opened', true)
      } else {
        this.$set(this[key[0]], i, !this[key[0]][i])
      }
    },
    touch(s, i) {
      if (this.bgIndex === i) return
      let key = s.split('.')
      if (key[1]) {
        if (this[key[0]][key[1]][i].opened) this.bgIndex = i
      } else {
        if (this[key[0]][i]) this.bgIndex = i
      }
    },
    scroll({srcElement}){
      if (srcElement.scrollTop > this.tabTop) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
    openAnswer(i, isOpen){
      this.bgIndex = i
      if (isOpen)
        this.$set(this.answerData[i], 'opened', true)
      else
        this.$set(this.answerData[i], 'opened', !this.answerData[i].opened)
    },
    openSuggest(e) {
      this.popupShow = true
      this.popupData = e
    }
  },
}
</script>
<style lang="scss" scoped>
.topSaleReport{
  box-shadow: 0 0 2px 0 rgba(200,200,200,.5);
  user-select: none;
  padding-bottom: 1px;
  height: auto;
  background: linear-gradient(to right,#ffd4bf,#ffd8c0);
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: #635349;
  $mainColor: #e96118;
  &::before{
    content: '';
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 800px;
    height: 320px;
    border-bottom-right-radius: 40px;
    background: linear-gradient(#fea51a,$mainColor);
  }
  header{
    padding: 40px 30px 10px;
    background-size: 100%;
    display: flex;
    align-items: center;
    font-size: 28px;
    text-align: left;
    color: #fff;
    position: relative;
    z-index: 2;
      img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
      }
      b{font-size: 30px;}
      .time{
        font-size: 26px;
        margin-top: 6px;
      }
  }

  .tabBox{
    position: relative;
    z-index: 10;
    height: 100px;
    ul{
      padding: 24px 24px 20px;
      overflow-x: scroll;
      flex-wrap: nowrap;
      &::-webkit-scrollbar{height: 0;display: none}
      &::after{
        content: '';
        height: 30px;
        width: 20px;
        flex-shrink: 0;
      }
      li{
        position: relative;
        flex-shrink: 0;
        padding: 0 12px;
        background: rgba(255,255,255,.4);
        color: #fff;
        line-height: 54px;
        height: 54px;
        font-size: 26px;
        white-space: nowrap;
        box-sizing: content-box;
        border-radius: 10px;
        box-shadow: 0 0 6px rgba(221, 129, 54, 0.1);
        margin-right: 16px;
        &:last-child{margin: 0;}
      }
      .active{
        z-index: 11;
        color: $mainColor;
        background: #fff2ec;
        &::after{
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 99%;
          border-style: solid;
          border-width: 12px 12px 0 12px;
          border-color: #fff2ec transparent transparent transparent;
          filter: drop-shadow(0px 2px 1px rgba(221, 129, 54, 0.1));
        }
      }
    }
    .fix{
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 800px;
      background: linear-gradient(#fea51a,#f88f1a);
    }
  }
  section{
    margin: 0 24px;
    padding: 30px 0;
    background: #fff2ec;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(200,200,200,.1);
    position: relative;
    z-index: 2;
    animation: fadeIn .2s;
    min-height: 90vh;
  }
    p{
      padding: 4px 30px;
      font-size: 26px;
      text-align: justify;
      line-height: 1.4;
      overflow: hidden;
    }
    h1{
      margin: 70px 0 30px;
      color: $mainColor;
      font-size: 28px;
      font-weight: bold;
    }
    .sectorTxt{
      color: #936e52;
      font-size: 26px;
      margin: 20px 30px 80px;
    }
    .teacher{
      color: #428494;
      padding-left: 70px;
      font-weight: bold;
      margin: 4px 0 40px;
      position: relative;
      font-size: 34px;
      &::before{
        content: '';
        position: absolute;
        left: 28px;
        top: 7px;
        width: 36px;
        height: 36px;
        background: url('../../../assets/images/report/ts_teacher.png') no-repeat;
        background-size: 100%;
      }
      span{
        font-weight: normal;
        font-size: 28px;
        position: relative;
        padding-right: 40px;
        &::before,&::after{
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 12px solid #428494;
          animation: triangleFade .6s infinite linear;
        }
        &::before{right: 16px;animation-delay: .3s;}
      }
      @keyframes triangleFade {
        0%{opacity: .3;}
        100%{opacity: 1;}
      }
    }
    .orange{color: $mainColor}
    .dot{
      padding-left: 54px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        left: 36px;
        top: 16px;
        border-radius: 50%;
        background: $mainColor;
      }
    }
    .dot0::before{background: #808080}
    .mar1{margin-left: 20px;}
    .martop{margin-top: 20px;font-size: 28px;font-weight: bold;}
    label{
      color: #b6b1ae;
      display: inline-block;
      font-size: 24px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        top: 14px;
        right: -24px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #d1cbc7;
      }
    }
    .boxOpen{
      label{display: none}
      p:nth-of-type(n+3){animation: expand .8s forwards;}
    }
    .boxOpen.boxWhite{
      transition: .5s;
      background: #fff;
      box-shadow: inset 0 0 8px rgba(200,200,200,.5);
    }
    @keyframes expand {
      0%{max-height: 0;padding-top: 0;padding-bottom: 0;}
      100%{max-height: 160px;padding-top: 10px;padding-bottom: 10px;}
    }
    .box3p p:nth-child(3){animation: expand 0s forwards;}
    .box1p p:nth-child(2){animation: expand .8s forwards;}
  @keyframes fadeIn {
    0%{transform: translateY(100px);opacity: 0;}
    100%{transform: translateY(0%);opacity: 1;}
  }
  .question{
    background: #fff2ec;
    text-align: left;
    padding: 40px 0;
    margin-top: -20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    &:nth-child(n+2){box-shadow: 0 -6px 6px rgba($color: #b19d91, $alpha: .1);}
    .orange{margin-top: 10px;}
    h2{
      color: $mainColor;
      font-size: 28px;
      margin: 0 30px;
      font-weight: normal;
      padding-right: 76px;
      position: relative;
      label{
        position: absolute;
        top: 4px;
        right: 20px;
        &::before{content: '展开';}
        &::after{transition: .3s;}
      }
    }
    h3{
      min-height: 64px;
      font-weight: normal;
      background: $mainColor;
      color: #fff;
      font-size: 26px;
      margin: 20px 30px;
      border-radius: 50px;
      padding: 10px 20px 10px 40px;
      line-height: 1.2;
      overflow: hidden;
      span{margin-right: 20px;font-size: 28px;}
    }
    h4{
      font-weight: normal;
      position: relative;
      font-size: 28px;
      color: $mainColor;
      border: 1px solid $mainColor;
      border-radius: 4px;
      margin: 40px auto 20px;
      width: 140px;
      letter-spacing: 2px;
      padding-left: 8px;
      white-space: nowrap;
      overflow: hidden;
      span{
        background: $mainColor;
        color: #fff;
        padding: 0 4px;
        flex: 1;
        margin-left: 2px;
      }
    }
    h6{
      color: $mainColor;
      font-size: 26px;
      padding: 4px 0;
      margin: 20px 30px 10px;
      font-weight: normal;
      line-height: 1.4;
      text-align: justify;
    }
    .word{
      max-height: 0;
      overflow: hidden;
      transition: .6s;
      .summary + p{font-weight: bold;}
      .mar{margin: 10px 0;}
    }
  }
  .quesOpen{
    h2 label{
      &::before{content: '收起';}
      &::after{transform: rotate(-180deg);}
    }
    .word{max-height: 6000px;}
    &.boxWhite{
      transition: .4s;
      background: #fff;
      box-shadow: inset 0 0 8px rgba(200,200,200,.5);
    }
  }
  .hideBox{
    p{
      transition: .5s linear;
      max-height: 200px;
    }
    &:not(.showBox) p:nth-child(2) ~ *{
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .showBox{
    label{display: none;}
    &.boxWhite{
      transition: .5s;
      background: #fff;
      box-shadow: inset 0 0 8px rgba(200,200,200,.5);
    }
  }
  .mask{
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 800px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0,0,0,.7);
    *::-webkit-scrollbar{display: none;}
    .popup{
      position: absolute;
      width: 94%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: #fff;
      border-radius: 10px;
      height: 90vh;
      text-align: left;
      padding-top: 60px;
      .container{
        height: 100%;
        overflow-y: auto;
        padding-bottom: 30px;
      }
      img{
        position: absolute;
        width: 50px;
        height: 50px;
        right: 10px;
        top: 10px;
        padding: 10px;
      }
      h6{
        margin: 0 30px 20px;
        font-size: 30px;
        color: $mainColor;
      }
    }
  }
  .ver{
    font-size: 22px;
    text-align: center;
    line-height: 60px;
    color: #f3b693;
  }
}
</style>